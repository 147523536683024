// noinspection JSUnusedGlobalSymbols
//
// ------------------------------------------------------------------------------
//      Generated GraphQL schema for Join Program API
// ------------------------------------------------------------------------------
//      Stage        : Acceptance
//      Source       : https://api.acc.joinprogram.pwstaging.tech/graphql
//      Generated at : Wed Nov 13 12:55:55 UTC 2024
// ------------------------------------------------------------------------------

import { gql } from 'apollo-angular'
import { Injectable } from '@angular/core'
import * as Apollo from 'apollo-angular'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
    ID: { input: string; output: string }
    String: { input: string; output: string }
    Boolean: { input: boolean; output: boolean }
    Int: { input: number; output: number }
    Float: { input: number; output: number }
    /** The `Cent` scalar type represents price in cents */
    Cent: { input: number; output: number }
    Date: { input: string; output: string }
    DateTime: { input: string; output: string }
    /** An ISO compliant day-number value: 1 (monday) through 7 (sunday) */
    IsoDayNumber: { input: 1 | 2 | 3 | 4 | 5 | 6 | 7; output: 1 | 2 | 3 | 4 | 5 | 6 | 7 }
    /** An ISO compliant week-number (1 through 53) */
    IsoWeekNumber: { input: number; output: number }
    /** An ISO compliant week-year value. This is not the same thing as the gregorian year! */
    IsoWeekYear: { input: number; output: number }
    /** Represents a Pubsub message ID */
    MessageID: { input: string; output: string }
    /** Represents a Semver version number such as "1.2.3" */
    Semver: { input: string; output: string }
    /** Represents a Semver version range such as " >= 1.2.3 || 3.x" */
    SemverRange: { input: string; output: string }
    /** A string value that will have one or multiple translations */
    TranslatableString: { input: string; output: string }
    /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
    Upload: { input: File; output: File }
}

/**
 * This enum specifies all types of access users might conceptually request.
 * One might want to view a list of items, edit a specific one or delete
 * all of a list of items. This enum, when combined with the {@link CrudAction}
 * enum, encodes all of these possible requests the system must be able to respond to.
 */
export enum AccessType {
    /**
   * The "EXACT" case indicates the assertion that a specific model or list of models can be operated on.
   * This is useful to check whether an authorizable can be presented with capabilities to perform
   * requested actions on this set of models.
   */
    Exact = 'EXACT',
    /**
   * The "SOME" case indicates the assertion that _some_ instances of a model
   * can be operated on, without necessarily specifying which models can come
   * into scope. This is useful for two situations:
   *
   * 1. to check whether an authorizable entity can be presented with indexes
   * of models (e.g. overview pages). In that case we don't need to know in
   * advance which instances will be presented, just that as per set permissions,
   * _some_ might show up.
   *
   * 2. When creating models. When a request for a model creation is received,
   * the models to be created do not yet exist. Therefore, we cannot match these
   * models against keys as we would with the EXACT access-type.
   * The initial authorization can only check that _some_ models of a certain
   * type may be created by the user. After creation, it _is_ possible to do
   * another authorization check against the key(s) of the just created model(s).
   */
    Some = 'SOME',
}

export interface Addition {
    readonly id: Scalars['ID']['output']
    readonly name: Scalars['String']['output']
    readonly price: Scalars['Cent']['output']
}

export interface AdditionBelongsTo {
    readonly connect: Scalars['ID']['input']
}

export interface AdditionSalesEntry {
    readonly addition: Addition
    readonly amountClaimed: Scalars['Int']['output']
    readonly amountIssued: Scalars['Int']['output']
    readonly amountReserved: Scalars['Int']['output']
}

export interface Allergen {
    readonly icon?: Maybe<Scalars['String']['output']>
    readonly id: Scalars['ID']['output']
    readonly name: Scalars['String']['output']
}


export interface AllergenIconArgs {
    variant?: InputMaybe<AllergenIconVariant>
}

/** Allowed resizes for the `variant` argument on the query `icon`. */
export enum AllergenIconVariant {
    Original = 'ORIGINAL',
}

export interface AllergensBelongsTo {
    readonly connect: Scalars['ID']['input']
}

export interface AllergensFilterInput {
    readonly query?: InputMaybe<Scalars['String']['input']>
}

export interface AllergensInput {
    readonly filters?: InputMaybe<LocationsFilterInput>
    readonly first: Scalars['Int']['input']
    readonly page?: InputMaybe<Scalars['Int']['input']>
}

export interface AllergensPaginator {
    readonly data: ReadonlyArray<Allergen>
    readonly paginatorInfo: PaginatorInfo
}

export interface AmbianceImages {
    readonly original: Scalars['String']['output']
    readonly rectangle_615: Scalars['String']['output']
}

export interface Answer {
    readonly answer: Scalars['String']['output']
    readonly answeredDate: Scalars['Date']['output']
    readonly id: Scalars['ID']['output']
    readonly note?: Maybe<Scalars['String']['output']>
    readonly question: Question
    readonly user: User
}

/** Answer enum */
export enum AnswerEnum {
    /** Agreement level 1 */
    AgreementLevel_1 = 'AGREEMENT_LEVEL_1',
    /** Agreement level 2 */
    AgreementLevel_2 = 'AGREEMENT_LEVEL_2',
    /** Agreement level 3 */
    AgreementLevel_3 = 'AGREEMENT_LEVEL_3',
    /** Agreement level 4 */
    AgreementLevel_4 = 'AGREEMENT_LEVEL_4',
    /** Agreement level 5 */
    AgreementLevel_5 = 'AGREEMENT_LEVEL_5',
    /** Smiley 1 */
    Smiley_1 = 'SMILEY_1',
    /** Smiley 2 */
    Smiley_2 = 'SMILEY_2',
    /** Smiley 3 */
    Smiley_3 = 'SMILEY_3',
    /** Smiley 4 */
    Smiley_4 = 'SMILEY_4',
    /** Star 1 */
    Star_1 = 'STAR_1',
    /** Star 2 */
    Star_2 = 'STAR_2',
    /** Star 3 */
    Star_3 = 'STAR_3',
    /** Star 4 */
    Star_4 = 'STAR_4',
    /** Star 5 */
    Star_5 = 'STAR_5',
}

export interface AnswersInput {
    readonly questionId: Scalars['ID']['input']
}

export interface ApiVersion {
    readonly createdAt: Scalars['DateTime']['output']
    readonly id: Scalars['ID']['output']
    readonly updatedAt: Scalars['DateTime']['output']
    readonly version: Scalars['String']['output']
}

export interface AppCompatibilityStatus {
    /** True if the API accepts the given app version. */
    readonly apiSupportsApp: Scalars['Boolean']['output']
    /** The current Semver version of the API. */
    readonly apiVersion: Scalars['Semver']['output']
    /** True if the app accepts the current API version. */
    readonly appSupportsApi: Scalars['Boolean']['output']
    /** The given Semver version of the app; is in the response for reference and debugging. */
    readonly appVersion: Scalars['Semver']['output']
    /** True if both the app and API are happy with each other's current version. */
    readonly compatible: Scalars['Boolean']['output']
}

export interface AssignProductCategoriesToRestaurantInput {
    readonly productCategoryIds: ReadonlyArray<Scalars['ID']['input']>
    readonly restaurantId: Scalars['ID']['input']
}

export interface AssignProductCategoryPricesToClientInput {
    readonly clientId: Scalars['ID']['input']
    readonly productCategoryPrices: ReadonlyArray<ProductCategoryPriceInput>
}

export interface AuthPayload {
    readonly access_token?: Maybe<Scalars['String']['output']>
    readonly expires_in?: Maybe<Scalars['Int']['output']>
    readonly refresh_token?: Maybe<Scalars['String']['output']>
    readonly token_type?: Maybe<Scalars['String']['output']>
    readonly user?: Maybe<User>
}

export interface AuthorizeClientInput {
    readonly clientId: Scalars['ID']['input']
    readonly clientSecret: Scalars['String']['input']
}

export interface ChangeOfficeDaysInput {
    readonly days: ReadonlyArray<InputMaybe<WeekDayEnum>>
}

export interface Client {
    readonly domains: ReadonlyArray<Domain>
    readonly emailQuestionsTo: Scalars['String']['output']
    readonly employeeCount: Scalars['Int']['output']
    readonly hasIdentityProvider: Scalars['Boolean']['output']
    readonly id: Scalars['ID']['output']
    readonly logo: Scalars['String']['output']
    readonly name: Scalars['String']['output']
    readonly newsPages: ReadonlyArray<NewsPage>
    readonly onboardingSteps: ReadonlyArray<OnboardingStepsEnum>
    readonly ratingContexts: ReadonlyArray<RatingContextsEnum>
    readonly registrationShortcode: Scalars['String']['output']
    readonly themes: ClientThemes
}

/** Client resolution strategy enum */
export enum ClientResolutionStrategyEnum {
    /** Domain */
    Domain = 'DOMAIN',
    /** Email */
    Email = 'EMAIL',
    /** Shortcode */
    Shortcode = 'SHORTCODE',
}

/**
 * Indicates the association between a Client and a Restaurant, i.e. that the Restaurant is made
 * available to the client. It also specifies the order-strategy that the restaurant maintains for
 * this particular client, as well as an optional note about the order-strategy that can be shown to
 * end users.
 */
export interface ClientRestaurantAssociation {
    readonly client: Client
    readonly orderStrategy: OrderStrategyEnum
    readonly orderStrategyNote?: Maybe<Scalars['String']['output']>
    readonly restaurant: Restaurant
}

export interface ClientRestaurantAssociationInput {
    readonly orderStrategy: OrderStrategyEnum
    readonly orderStrategyNote?: InputMaybe<Scalars['String']['input']>
    readonly restaurantId: Scalars['ID']['input']
}

export interface ClientTheme {
    readonly colorError: Scalars['String']['output']
    readonly colorErrorContrast: Scalars['String']['output']
    readonly colorPrimary: Scalars['String']['output']
    readonly colorPrimaryContrast: Scalars['String']['output']
    readonly colorSecondary: Scalars['String']['output']
    readonly colorSecondaryContrast: Scalars['String']['output']
    readonly colorSuccess: Scalars['String']['output']
    readonly colorSuccessContrast: Scalars['String']['output']
    readonly colorTertiary: Scalars['String']['output']
    readonly colorTertiaryContrast: Scalars['String']['output']
    readonly colorTextDark: Scalars['String']['output']
    readonly colorTextLight: Scalars['String']['output']
    readonly colorWarning: Scalars['String']['output']
    readonly colorWarningContrast: Scalars['String']['output']
    readonly contextualColors: ReadonlyArray<ContextualColor>
}

export interface ClientThemes {
    readonly app: ClientTheme
    readonly web: ClientTheme
}

/** Lists all the associations and their auxiliary pivot data of Restaurants with a particular Client. */
export interface ClientWithRestaurantAssociations {
    readonly associations: ReadonlyArray<ClientRestaurantAssociation>
    readonly client: Client
}

export interface ClientsFilterInput {
    readonly query?: InputMaybe<Scalars['String']['input']>
}

export interface ClientsInput {
    readonly filters?: InputMaybe<ClientsFilterInput>
    readonly first: Scalars['Int']['input']
    readonly page?: InputMaybe<Scalars['Int']['input']>
}

export interface ClientsPaginator {
    readonly data: ReadonlyArray<Client>
    readonly paginatorInfo: PaginatorInfo
}

export interface ContentMedia {
    readonly fileName: Scalars['String']['output']
    readonly id: Scalars['ID']['output']
    readonly media: Scalars['String']['output']
    readonly newsItems?: Maybe<ReadonlyArray<NewsItem>>
}


export interface ContentMediaMediaArgs {
    variant?: InputMaybe<ContentMediaMediaVariant>
}

/** Allowed resizes for the `variant` argument on the query `media`. */
export enum ContentMediaMediaVariant {
    Original = 'ORIGINAL',
    Rectangle_1200 = 'RECTANGLE_1200',
}

export interface ContentMediaPaginator {
    readonly data: ReadonlyArray<ContentMedia>
    readonly paginatorInfo: PaginatorInfo
}

/**
 * Union type for all mediable content types.
 * Currently only NewsItem.
 */
export type ContentMediable = NewsItem

export interface ContentMediasInput {
    readonly pagination: PaginationInput
}

export interface ContextualColor {
    readonly name: Scalars['String']['output']
    readonly value: Scalars['String']['output']
}

export interface ContextualColorInput {
    readonly name: Scalars['String']['input']
    readonly value: Scalars['String']['input']
}

export interface CreateAdditionInput {
    readonly name: Scalars['String']['input']
    readonly price: Scalars['Cent']['input']
}

export interface CreateAnswerInput {
    /**
   * - For Multiple choice type questions: `"1" | "2" | "3" | "4" | "5"`
   * - For Star type questions: `"1" | "2" | "3" | "4" | "5"`
   * - For Smiley type questions: `"1" | "2" | "3" | "4"`
   * - For Open-ended type questions: any string
   */
    readonly answer: Scalars['String']['input']
    readonly note?: InputMaybe<Scalars['String']['input']>
    readonly questionId: Scalars['ID']['input']
}

export interface CreateClientInput {
    readonly emailQuestionsTo: Scalars['String']['input']
    readonly logo?: InputMaybe<DeletableFileInput>
    readonly name: Scalars['String']['input']
    readonly onboardingSteps: ReadonlyArray<OnboardingStepsEnum>
    readonly ratingContexts: ReadonlyArray<RatingContextsEnum>
}

export interface CreateDishRatingInput {
    readonly consumptionDate: Scalars['Date']['input']
    readonly note?: InputMaybe<Scalars['String']['input']>
    readonly productId: Scalars['ID']['input']
    readonly rating: Scalars['Int']['input']
    readonly restaurantId: Scalars['ID']['input']
    readonly type: DishRatingTypeEnum
}

export interface CreateDismissRatingInput {
    readonly consumptionDate: Scalars['Date']['input']
    readonly productId: Scalars['ID']['input']
}

export interface CreateDomainInput {
    readonly clientId: Scalars['ID']['input']
    readonly domains: ReadonlyArray<DomainInput>
}

export interface CreateInternalVoucherInput {
    readonly activeFrom: Scalars['DateTime']['input']
    readonly activeUntil: Scalars['DateTime']['input']
    readonly initialAmount: Scalars['Cent']['input']
    readonly userId: Scalars['ID']['input']
}

export interface CreateLocationInput {
    readonly cityName: Scalars['String']['input']
    readonly costCenter?: InputMaybe<Scalars['String']['input']>
    readonly name: Scalars['String']['input']
    readonly postalCode: Scalars['String']['input']
    readonly streetName: Scalars['String']['input']
    readonly streetNumber: Scalars['String']['input']
}

export interface CreateLoginSessionInput {
    readonly codeChallenge: Scalars['String']['input']
    readonly email?: InputMaybe<Scalars['String']['input']>
    readonly fallbackRedirectUri: Scalars['String']['input']
    readonly redirectUri: Scalars['String']['input']
    readonly shortcode?: InputMaybe<Scalars['String']['input']>
}

export interface CreateLoginSessionResponse {
    readonly clientResolutionStrategy: ClientResolutionStrategyEnum
    readonly isFallbackRedirectUri: Scalars['Boolean']['output']
    readonly redirectUri: Scalars['String']['output']
}

export interface CreateNutrientForProductVersionInput {
    readonly nutrientTypeId: Scalars['ID']['input']
    readonly perHundredGram: Scalars['Float']['input']
}

export interface CreatePollInput {
    readonly clients: ReadonlyArray<QuestionClientInput>
    readonly endDate: Scalars['Date']['input']
    readonly startDate: Scalars['Date']['input']
    readonly translations: ReadonlyArray<QuestionTranslationInput>
    readonly type: QuestionTypeEnum
}

export interface CreateProductCategoryInput {
    readonly name: Scalars['String']['input']
    readonly publicCategory: Scalars['ID']['input']
}

export interface CreateProductInput {
    readonly additions?: InputMaybe<ReadonlyArray<CreateAdditionInput>>
    readonly allergens?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly category: Scalars['ID']['input']
    readonly description?: InputMaybe<Scalars['String']['input']>
    readonly dietaryClass?: InputMaybe<Scalars['String']['input']>
    readonly image?: InputMaybe<DeletableFileInput>
    readonly marginPercentage: Scalars['Int']['input']
    readonly name: Scalars['String']['input']
    readonly nutrients?: InputMaybe<ReadonlyArray<CreateNutrientForProductVersionInput>>
    readonly price: Scalars['Cent']['input']
    readonly shortDescription?: InputMaybe<Scalars['String']['input']>
    readonly tagline?: InputMaybe<Scalars['String']['input']>
    readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>
    readonly type: ProductTypeEnum
}

export interface CreatePublicCategoryInput {
    readonly name: Scalars['String']['input']
}

export interface CreateRestaurantInput {
    readonly allowPickUpAtTheOffice: Scalars['Boolean']['input']
    readonly ambianceImage?: InputMaybe<DeletableFileInput>
    readonly dishBackgroundImage?: InputMaybe<DeletableFileInput>
    readonly locationId?: InputMaybe<Scalars['ID']['input']>
    readonly name: Scalars['String']['input']
    readonly orderDeadlineMarginMinutes: Scalars['Int']['input']
    readonly pickupInstructions?: InputMaybe<Scalars['String']['input']>
    readonly qrCodePickup: Scalars['Boolean']['input']
    readonly serviceReferenceId?: InputMaybe<Scalars['String']['input']>
    readonly type: RestaurantTypeEnum
}

export interface CreateUserInput {
    readonly clientId: Scalars['ID']['input']
    readonly email: Scalars['String']['input']
    readonly firstName: Scalars['String']['input']
    readonly lastName: Scalars['String']['input']
    readonly locationId?: InputMaybe<Scalars['ID']['input']>
    readonly roles?: InputMaybe<ReadonlyArray<RoleInput>>
}

/** Creation source enum */
export enum CreationSourceEnum {
    /** Backoffice menu creator */
    BackofficeMenuCreator = 'BACKOFFICE_MENU_CREATOR',
    /** Written from template */
    WrittenFromTemplate = 'WRITTEN_FROM_TEMPLATE',
}

export enum CrudAction {
    Create = 'CREATE',
    Delete = 'DELETE',
    Read = 'READ',
    Update = 'UPDATE',
}

/** Currency enum */
export enum CurrencyEnum {
    /** Euro */
    Euro = 'EURO',
    /** Great britain pound */
    GreatBritainPound = 'GREAT_BRITAIN_POUND',
    /** United states dollar */
    UnitedStatesDollar = 'UNITED_STATES_DOLLAR',
}

/** The response type of the `dailyMenu` GraphQL query. */
export interface DailyMenu {
    readonly date: Scalars['Date']['output']
    readonly restaurants: ReadonlyArray<DailyMenuRestaurant>
}

export interface DailyMenuFilters {
    readonly dietaryClasses?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>
    readonly locations?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    /** DEPRECATED. Use publicCategories instead. */
    readonly productCategories?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly productTypes?: InputMaybe<ReadonlyArray<ProductTypeEnum>>
    readonly publicCategories?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly restaurants?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
}

/** A single item served at the containing restaurant on the queried date. */
export interface DailyMenuItem {
    readonly additions: ReadonlyArray<Addition>
    readonly allergens: ReadonlyArray<Allergen>
    /** @deprecated Use publicCategory instead. */
    readonly category: ProductCategory
    readonly context: DailyMenuItemContext
    readonly description?: Maybe<Scalars['String']['output']>
    readonly dietaryClass?: Maybe<Scalars['String']['output']>
    readonly images?: Maybe<DailyMenuItemImages>
    readonly name: Scalars['String']['output']
    readonly nutrients: ReadonlyArray<Nutrient>
    readonly price: Scalars['Cent']['output']
    readonly publicCategory: PublicCategory
    readonly sources: DailyMenuItemSourceIDs
    readonly stock: StockNumbers
    readonly tagline?: Maybe<Scalars['String']['output']>
    readonly tags: ReadonlyArray<Tag>
    readonly type: ProductTypeEnum
}

/**
 * A context object that tells where in the DailyMenu type a DailyMenuItem was provided.
 * This can be useful when an item is taken out of context in a front-end application but
 * the determining properties of its context are ad-hoc required for any reason.
 */
export interface DailyMenuItemContext {
    readonly date: Scalars['Date']['output']
    readonly restaurantID: Scalars['ID']['output']
}

/**
 * The set of images in different sizes for a DailyMenuItem.
 * Note: ORIGINAL may be very large and expensive to render.
 */
export interface DailyMenuItemImages {
    readonly original: Scalars['String']['output']
    readonly square150: Scalars['String']['output']
    readonly square300: Scalars['String']['output']
    readonly square600: Scalars['String']['output']
}

/**
 * The unique IDs of most sources used for generating a DailyMenuItem object.
 * These IDs may be necessary as references when placing orders.
 */
export interface DailyMenuItemSourceIDs {
    readonly menu: Scalars['ID']['output']
    readonly menuEntry: Scalars['ID']['output']
    readonly menuEntryOccurrence: Scalars['ID']['output']
    readonly product: Scalars['ID']['output']
    readonly productVersion: Scalars['ID']['output']
}

export interface DailyMenuRestaurant {
    readonly items: ReadonlyArray<DailyMenuItem>
    readonly restaurant: Restaurant
}

export interface DateNotations {
    readonly isoDate: Scalars['Date']['output']
    readonly isoWeekDate: Scalars['String']['output']
}

export interface DeletableFileInput {
    readonly delete?: InputMaybe<Scalars['Boolean']['input']>
    readonly file?: InputMaybe<Scalars['Upload']['input']>
}

export interface DeliveryAddressInput {
    readonly cityName: Scalars['String']['input']
    readonly postalCode: Scalars['String']['input']
    readonly streetName: Scalars['String']['input']
    readonly streetNumber: Scalars['String']['input']
}

export interface DietaryClass {
    readonly key: Scalars['String']['output']
    readonly value: Scalars['String']['output']
}

export interface DishBackgroundImages {
    readonly ORIGINAL: Scalars['String']['output']
    readonly SQUARE_150: Scalars['String']['output']
    readonly SQUARE_300: Scalars['String']['output']
    readonly SQUARE_600: Scalars['String']['output']
}

export interface DishRating {
    readonly consumptionDate: Scalars['Date']['output']
    readonly createdAt: Scalars['DateTime']['output']
    readonly id: Scalars['ID']['output']
    readonly note?: Maybe<Scalars['String']['output']>
    readonly product: Product
    readonly rating: Scalars['Int']['output']
    readonly restaurant: Restaurant
    readonly type: DishRatingTypeEnum
    readonly user: User
}

/** A single average rating with associated data for the product by contained ID. */
export interface DishRatingAverage {
    readonly averageRating: Scalars['Float']['output']
    readonly clientNames: ReadonlyArray<Scalars['String']['output']>
    readonly productCategoryID: Scalars['ID']['output']
    readonly productCategoryName: Scalars['String']['output']
    readonly productID: Scalars['ID']['output']
    readonly productName: Scalars['String']['output']
    readonly totalRatings: Scalars['Int']['output']
}

export interface DishRatingAveragesInput {
    readonly clientIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly endDate: Scalars['Date']['input']
    readonly startDate: Scalars['Date']['input']
}

export interface DishRatingAveragesPaginator {
    readonly data: ReadonlyArray<DishRatingAverage>
    readonly paginatorInfo: PaginatorInfo
}

/** Dish rating type enum */
export enum DishRatingTypeEnum {
    /** Dish detail */
    DishDetail = 'DISH_DETAIL',
    /** Ordered dish */
    OrderedDish = 'ORDERED_DISH',
}

export interface DishRatingsDetailExportInput {
    readonly clientIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly endDate: Scalars['Date']['input']
    readonly restaurantIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly startDate: Scalars['Date']['input']
}

export interface DishRatingsExportInput {
    readonly clientIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly endDate: Scalars['Date']['input']
    readonly productId: Scalars['ID']['input']
    readonly restaurantIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly startDate: Scalars['Date']['input']
}

export interface DishRatingsPaginator {
    readonly data: ReadonlyArray<DishRating>
    readonly paginatorInfo: PaginatorInfo
}

export interface DishRatingsStatisticsInput {
    readonly categories?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly clients?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly endDate?: InputMaybe<Scalars['Date']['input']>
    readonly searchQuery?: InputMaybe<Scalars['String']['input']>
    readonly startDate?: InputMaybe<Scalars['Date']['input']>
}

export interface DismissedRating {
    readonly consumptionDate: Scalars['Date']['output']
    readonly id: Scalars['ID']['output']
    readonly product: Product
    readonly user: User
}

export interface Domain {
    readonly client: Client
    readonly id: Scalars['ID']['output']
    readonly name: Scalars['String']['output']
}

export interface DomainInput {
    readonly name: Scalars['String']['input']
}

export interface ExternalCategory {
    readonly createdAt: Scalars['DateTime']['output']
    readonly id: Scalars['ID']['output']
    readonly name: Scalars['String']['output']
    readonly productCategory?: Maybe<ProductCategory>
    readonly source: ExternalCategorySourceEnum
    readonly updatedAt: Scalars['DateTime']['output']
}

/** External category source enum */
export enum ExternalCategorySourceEnum {
    /** Apicbase */
    Apicbase = 'APICBASE',
}

/** Typed input for generateFeedbackExport */
export interface FeedbackExportInput {
    readonly clientId: Scalars['ID']['input']
    readonly endDate: Scalars['Date']['input']
    readonly startDate: Scalars['Date']['input']
}

/** Feedback sortable column enum */
export enum FeedbackSortableColumnEnum {
    /** Average rating */
    AverageRating = 'AVERAGE_RATING',
    /** Total ratings */
    TotalRatings = 'TOTAL_RATINGS',
}

/**
 * FileExport type
 * The url field will contain the url to the file download
 */
export interface FileExport {
    readonly createdAt: Scalars['DateTime']['output']
    readonly downloadId: Scalars['String']['output']
    readonly fileName?: Maybe<Scalars['String']['output']>
    readonly fileSize?: Maybe<Scalars['Int']['output']>
    readonly id: Scalars['ID']['output']
    readonly status: FileExportStatusEnum
    readonly type: FileExportTypeEnum
    readonly updatedAt: Scalars['DateTime']['output']
    readonly url?: Maybe<Scalars['String']['output']>
    readonly user?: Maybe<User>
}

/** Typed response for FileExportInput */
export interface FileExportInputResponse {
    readonly fileId?: Maybe<Scalars['ID']['output']>
    readonly status: FileExportInputResponseStatusEnum
    readonly type: FileExportTypeEnum
}

/** File export input response status enum */
export enum FileExportInputResponseStatusEnum {
    /** Failed */
    Failed = 'FAILED',
    /** Success */
    Success = 'SUCCESS',
}

/** File export status enum */
export enum FileExportStatusEnum {
    /** Downloaded */
    Downloaded = 'DOWNLOADED',
    /** Failed */
    Failed = 'FAILED',
    /** Finished */
    Finished = 'FINISHED',
    /** Pending */
    Pending = 'PENDING',
    /** Processing */
    Processing = 'PROCESSING',
}

/** File export type enum */
export enum FileExportTypeEnum {
    /** Dish ratings */
    DishRatings = 'DISH_RATINGS',
    /** Dish rating averages */
    DishRatingAverages = 'DISH_RATING_AVERAGES',
    /** Dish rating detail */
    DishRatingDetail = 'DISH_RATING_DETAIL',
    /** Feedback */
    Feedback = 'FEEDBACK',
    /** Finance */
    Finance = 'FINANCE',
    /** Orders */
    Orders = 'ORDERS',
    /** Vouchers */
    Vouchers = 'VOUCHERS',
}

export interface FileExportsPaginator {
    readonly data: ReadonlyArray<FileExport>
    readonly paginatorInfo: PaginatorInfo
}

export interface FinanceDataFilterInput {
    readonly clients?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly endDate: Scalars['Date']['input']
    readonly restaurants?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly startDate: Scalars['Date']['input']
}

export interface FinanceDataForPayment {
    readonly financeData: FinanceDataPaymentSubjects
    readonly paymentMethod: Scalars['String']['output']
}

export interface FinanceDataForProductCategory {
    readonly financeData: FinanceDataSubjects
    readonly productCategory: Scalars['String']['output']
}

export interface FinanceDataForProductType {
    readonly financeData: FinanceDataSubjects
    readonly productType: ProductTypeEnum
}

export interface FinanceDataForSummary {
    readonly financeData: FinanceDataSummarySubjects
    readonly type: Scalars['String']['output']
}

export interface FinanceDataPaymentSubjects {
    readonly average: Scalars['Float']['output']
    readonly created: Scalars['Int']['output']
    readonly feesAverage: Scalars['Float']['output']
    readonly feesTotal: Scalars['Float']['output']
    readonly successful: Scalars['Int']['output']
    readonly total: Scalars['Float']['output']
}

export interface FinanceDataSubjects {
    readonly averageSumWithVat: Scalars['Float']['output']
    readonly averageSumWithoutVat: Scalars['Float']['output']
    readonly sumWithVat: Scalars['Float']['output']
    readonly sumWithoutVat: Scalars['Float']['output']
    readonly totalNumberOfTickets: Scalars['Int']['output']
}

export interface FinanceDataSummarySubjects {
    readonly additionCostPrice: Scalars['Float']['output']
    readonly averageCostPrice: Scalars['Float']['output']
    readonly averageOrderMargin: Scalars['Float']['output']
    readonly averageWithVat: Scalars['Float']['output']
    readonly averageWithoutVat: Scalars['Float']['output']
    readonly costPrice: Scalars['Float']['output']
    readonly orderCount: Scalars['Int']['output']
    readonly perVAT: ReadonlyArray<FinanceDataSummaryVat>
    readonly salesMargin: Scalars['Float']['output']
    readonly withVAT: Scalars['Float']['output']
    readonly withoutVAT: Scalars['Float']['output']
}

export interface FinanceDataSummaryVat {
    readonly totalRevenue: Scalars['Float']['output']
    readonly vatTariff: Scalars['Float']['output']
}

export interface FinancialOrdersExportInput {
    readonly clientIds: ReadonlyArray<Scalars['ID']['input']>
    readonly endDate: Scalars['Date']['input']
    readonly restaurantIds: ReadonlyArray<Scalars['ID']['input']>
    readonly startDate: Scalars['Date']['input']
}

export interface FinishLoginSessionInput {
    readonly acceptsPrivacyPolicy?: InputMaybe<Scalars['Boolean']['input']>
    readonly acceptsTerms?: InputMaybe<Scalars['Boolean']['input']>
    readonly codeVerifier: Scalars['String']['input']
    readonly exchangeToken: Scalars['String']['input']
}

export interface ForgotPasswordInput {
    readonly callbackUrl: ResetPasswordUrlInput
    readonly email: Scalars['String']['input']
}

export interface ForgotPasswordResponse {
    readonly message?: Maybe<Scalars['String']['output']>
    readonly status: Scalars['String']['output']
}

/** Integration provider enum */
export enum IntegrationProviderEnum {
    /** Apicbase */
    Apicbase = 'APICBASE',
    /** Internal voucher */
    InternalVoucher = 'INTERNAL_VOUCHER',
    /** Okta */
    Okta = 'OKTA',
    /** Planon */
    Planon = 'PLANON',
}

export interface InternalVoucher {
    readonly activeFrom: Scalars['DateTime']['output']
    readonly activeUntil: Scalars['DateTime']['output']
    readonly code: Scalars['String']['output']
    readonly id: Scalars['ID']['output']
    readonly initialAmount: Scalars['Cent']['output']
    readonly usedAmount: Scalars['Cent']['output']
    readonly user: User
}

export interface InternalVouchersFiltersInput {
    readonly activeFrom?: InputMaybe<Scalars['Date']['input']>
    readonly activeUntil?: InputMaybe<Scalars['Date']['input']>
    readonly clientId?: InputMaybe<Scalars['ID']['input']>
    readonly email?: InputMaybe<Scalars['String']['input']>
}

export interface InternalVouchersInput {
    readonly filters?: InputMaybe<InternalVouchersFiltersInput>
    readonly first: Scalars['Int']['input']
    readonly page?: InputMaybe<Scalars['Int']['input']>
}

export interface InternalVouchersPaginator {
    readonly data: ReadonlyArray<InternalVoucher>
    readonly paginatorInfo: PaginatorInfo
}

export interface IsRatedByUserInput {
    readonly consumptionDate: Scalars['Date']['input']
    readonly productId: Scalars['ID']['input']
    readonly type: DishRatingTypeEnum
}

/** Item source enum */
export enum ItemSourceEnum {
    /** Apic base */
    ApicBase = 'APIC_BASE',
    /** Internal */
    Internal = 'INTERNAL',
}

export interface LatestAppVersion {
    /** The latest Semver version of the Android app. */
    readonly android?: Maybe<Scalars['Semver']['output']>
    /** The latest Semver version of the IOS app. */
    readonly ios?: Maybe<Scalars['Semver']['output']>
}

export interface Locale {
    readonly name: Scalars['String']['output']
}

export interface Location {
    readonly cityName: Scalars['String']['output']
    readonly costCenter?: Maybe<Scalars['String']['output']>
    readonly id: Scalars['ID']['output']
    readonly name: Scalars['String']['output']
    readonly postalCode: Scalars['String']['output']
    readonly restaurants: ReadonlyArray<Restaurant>
    readonly shortcode?: Maybe<Scalars['String']['output']>
    readonly streetName: Scalars['String']['output']
    readonly streetNumber: Scalars['String']['output']
}

export interface LocationBelongsTo {
    readonly connect: Scalars['ID']['input']
}

export interface LocationsFilterInput {
    readonly clients?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly query?: InputMaybe<Scalars['String']['input']>
}

export interface LocationsInput {
    readonly filters?: InputMaybe<LocationsFilterInput>
    readonly first: Scalars['Int']['input']
    readonly page?: InputMaybe<Scalars['Int']['input']>
}

export interface LocationsPaginator {
    readonly data: ReadonlyArray<Location>
    readonly paginatorInfo: PaginatorInfo
}

export interface LoginInput {
    readonly clientId: Scalars['ID']['input']
    readonly clientSecret: Scalars['String']['input']
    readonly password: Scalars['String']['input']
    readonly twoFactorCode?: InputMaybe<Scalars['String']['input']>
    readonly twoFactorRecoveryCode?: InputMaybe<Scalars['String']['input']>
    readonly username: Scalars['String']['input']
}

export interface LoginWithShortcodeInput {
    readonly clientId: Scalars['ID']['input']
    readonly clientSecret: Scalars['String']['input']
    readonly shortcode: Scalars['String']['input']
}

export interface LogoutResponse {
    readonly message?: Maybe<Scalars['String']['output']>
    readonly status: Scalars['String']['output']
}

export interface Menu {
    /**
   * The ISO-8601 compliant (with kept TZ offset) date-time lower-bound of the week this menu represents.
   * E.g. '2024-01-01T00:00:00.000000+01:00' for Week 1, 2024 in West European time zone.
   */
    readonly dateTimeLowerBound: Scalars['String']['output']
    /**
   * The ISO-8601 compliant (with kept TZ offset) date-time upper-bound of the week this menu represents.
   * E.g. '2024-01-07T23:59:59.999999+01:00' for Week 1, 2024 in West European time zone.
   */
    readonly dateTimeUpperBound: Scalars['String']['output']
    readonly dates: MenuDates
    readonly entries: ReadonlyArray<MenuEntry>
    readonly id: Scalars['ID']['output']
    readonly isPublished: Scalars['Boolean']['output']
    readonly isoWeekNotation: Scalars['String']['output']
    readonly isoWeekNumber: Scalars['IsoWeekNumber']['output']
    readonly isoWeekYear: Scalars['IsoWeekYear']['output']
    /**
   * When the menu is published, this contains the log of the publishing
   * process. This is a JSON string and can be quite large!
   */
    readonly publicationLog?: Maybe<Scalars['String']['output']>
}

export interface MenuDates {
    readonly FRIDAY: DateNotations
    readonly MONDAY: DateNotations
    readonly SATURDAY: DateNotations
    readonly SUNDAY: DateNotations
    readonly THURSDAY: DateNotations
    readonly TUESDAY: DateNotations
    readonly WEDNESDAY: DateNotations
}

export interface MenuEntriesQueryFilter {
    readonly creationSources?: InputMaybe<ReadonlyArray<CreationSourceEnum>>
    readonly productCategories?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly productTypes?: InputMaybe<ReadonlyArray<ProductTypeEnum>>
    readonly tags?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
}

export interface MenuEntry {
    readonly creationSource: CreationSourceEnum
    readonly date: Scalars['Date']['output']
    readonly id: Scalars['ID']['output']
    readonly isoDayNumber: Scalars['IsoDayNumber']['output']
    readonly menu: Menu
    readonly occurrences: ReadonlyArray<MenuEntryOccurrence>
    readonly position: Scalars['Int']['output']
    readonly product: Product
    readonly tags: ReadonlyArray<MenuEntryTag>
    readonly weekday: WeekDayEnum
}

export interface MenuEntryCreationInput {
    readonly menuId: Scalars['ID']['input']
    readonly productId: Scalars['ID']['input']
    readonly weekday: WeekDayEnum
}

export interface MenuEntryOccurrence {
    readonly creationSource: CreationSourceEnum
    readonly entry: MenuEntry
    readonly entryId: Scalars['ID']['output']
    readonly id: Scalars['ID']['output']
    readonly position: Scalars['Int']['output']
    readonly prices: ReadonlyArray<MenuEntryOccurrencePrice>
    readonly restaurant: Restaurant
    readonly stockClaimed: Scalars['Int']['output']
    readonly stockIssued: Scalars['Int']['output']
    readonly stockTotalCorrection: Scalars['Int']['output']
    readonly stockTotalInitial?: Maybe<Scalars['Int']['output']>
}

export interface MenuEntryOccurrenceInput {
    readonly menuEntryId: Scalars['ID']['input']
    readonly prices: ReadonlyArray<MenuEntryOccurrencePriceInput>
    readonly stockTotalInitial?: InputMaybe<Scalars['Int']['input']>
}

export interface MenuEntryOccurrencePositioningInput {
    readonly menuEntryOccurrenceId: Scalars['ID']['input']
    readonly position: Scalars['Int']['input']
}

export interface MenuEntryOccurrencePrice {
    readonly client: Client
    readonly id: Scalars['ID']['output']
    readonly occurrence: MenuEntryOccurrence
    readonly sellingPrice: Scalars['Cent']['output']
}

export interface MenuEntryOccurrencePriceInput {
    readonly clientId: Scalars['ID']['input']
    readonly sellingPrice: Scalars['Cent']['input']
}

export interface MenuEntryOccurrencesQueryFilter {
    readonly creationSources?: InputMaybe<ReadonlyArray<CreationSourceEnum>>
    readonly entries?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly restaurants?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
}

export interface MenuEntryPositioningInput {
    readonly menuEntryId: Scalars['ID']['input']
    readonly position: Scalars['Int']['input']
}

export interface MenuEntryTag {
    readonly entries: ReadonlyArray<MenuEntry>
    readonly hexColor: Scalars['String']['output']
    readonly id: Scalars['ID']['output']
    readonly name: Scalars['String']['output']
}

export interface MenuEntryTagChanges {
    readonly attached: ReadonlyArray<Scalars['ID']['output']>
    readonly detached: ReadonlyArray<Scalars['ID']['output']>
    readonly updated: ReadonlyArray<Scalars['ID']['output']>
}

export interface MenuEntryTagResult {
    readonly changes: MenuEntryTagChanges
    readonly menuEntryId: Scalars['ID']['output']
}

export interface Mutation {
    /**
   * Assigns the given list of Client associations to the Restaurant by given ID.
   *
   * ---
   *
   * The input data is considered the **complete set of associations**. If a pivot record already exists
   * for one of the given associations, the provided auxiliary data overrides corresponding data on the
   * existing pivot record. If it does not exist, a new association will be created. The mutation also
   * deletes associations that pre-exist at call time but are not included in the mutation input data.
   */
    readonly assignClientsToRestaurant: RestaurantWithClientAssociations
    readonly assignProductCategoriesToRestaurant: RestaurantWithProductCategories
    readonly assignProductCategoryPricesToClient: ReadonlyArray<ProductCategoryPrice>
    /**
   * Assigns the given set of occurrences with stock and selling-price information to the restaurant by given ID
   * for the week by given ISO week parameters.
   *
   * The input data is considered the **complete set of occurrences**. If a MenuEntryOccurrence already exists
   * for one of the given occurrences, the provided data is patched on the existing record and its related price
   * records. If it does not exist, a new one will be created. Existing occurrences that are not repeated in the
   * input data are deleted while that does not compromise the menu integrity. Throws a validation error otherwise.
   */
    readonly assignRestaurantMenu: ReadonlyArray<MenuEntryOccurrence>
    /**
   * Assigns the given list of Restaurant associations to the Client by given ID.
   *
   * ---
   *
   * The input data is considered the **complete set of associations**. If a pivot record already exists
   * for one of the given associations, the provided auxiliary data overrides corresponding data on the
   * existing pivot record. If it does not exist, a new association will be created. The mutation also
   * deletes associations that pre-exist at call time but are not included in the mutation input data.
   */
    readonly assignRestaurantsToClient: ClientWithRestaurantAssociations
    readonly associateExternalCategory: ExternalCategory
    readonly authorizeClient: AuthPayload
    readonly createAnswer: Answer
    readonly createClient: Client
    readonly createContentMedia: ContentMedia
    readonly createDishRating: DishRating
    readonly createDismissRating: DismissedRating
    readonly createDomains: ReadonlyArray<Domain>
    readonly createInternalVoucher?: Maybe<InternalVoucher>
    readonly createLocation: Location
    readonly createLoginSession: CreateLoginSessionResponse
    readonly createMenuEntries: ReadonlyArray<MenuEntry>
    /**
   * Creates a new menu-entry tag with the given name and HEX color values.
   * The given name must be unique.
   */
    readonly createMenuEntryTag: MenuEntryTag
    readonly createNewsItem: NewsItem
    readonly createOrUpdateClientTheme?: Maybe<ClientTheme>
    /**
   * Creates one or multiple orders for the given input. Returns the list of created orders upon success.
   * When called with an empty list this mutation will fail.
   */
    readonly createOrders: ReadonlyArray<Order>
    readonly createPayment: Payment
    readonly createPoll: Question
    readonly createProduct: Product
    readonly createProductCategory?: Maybe<ProductCategory>
    readonly createPublicCategory: PublicCategory
    readonly createRestaurant: Restaurant
    readonly createTemplateMenu: TemplateMenu
    readonly createTemplateMenuEntries: ReadonlyArray<TemplateMenuEntry>
    readonly createUser: User
    readonly deleteClient?: Maybe<Scalars['Boolean']['output']>
    readonly deleteDomain: Scalars['Boolean']['output']
    readonly deleteFileExport?: Maybe<Scalars['Boolean']['output']>
    readonly deleteInternalVoucher?: Maybe<Scalars['Boolean']['output']>
    readonly deleteMe: Scalars['Boolean']['output']
    /**
   * Deletes the menu-entries by given IDs. Only entries of unpublished menus may be deleted like this. This
   * endpoint will throw when any of the given IDs is of a published entry.
   */
    readonly deleteMenuEntries: Scalars['Boolean']['output']
    readonly deleteMenuEntryOccurrence: Scalars['Boolean']['output']
    /** Deletes the tag by given ID. */
    readonly deleteMenuEntryTag: Scalars['Boolean']['output']
    readonly deleteNewsItem: Scalars['Boolean']['output']
    readonly deletePoll: Scalars['Boolean']['output']
    readonly deleteProduct: Scalars['Boolean']['output']
    /** Deletes the template menu that currently exists by the given ID. */
    readonly deleteTemplateMenu: Scalars['Boolean']['output']
    /** Deletes the template menu-entries that currently exist by the given IDs. Returns true upon success. */
    readonly deleteTemplateMenuEntries: Scalars['Boolean']['output']
    readonly deleteUser: Scalars['Boolean']['output']
    readonly disableTwoFactorAuthentication: Scalars['Boolean']['output']
    readonly finishLoginSession: AuthPayload
    readonly forgotPassword: Scalars['String']['output']
    readonly generateDishRatingAveragesExport: FileExportInputResponse
    readonly generateDishRatingDetailExport: FileExportInputResponse
    readonly generateDishRatingsExport: FileExportInputResponse
    /** Request a download of feedback export data by given parameters. */
    readonly generateFeedbackExport: FileExportInputResponse
    /** Generate a file-export for the financial overview of orders. */
    readonly generateFinancialOrdersExport: FileExportInputResponse
    /** Generate a file-export for the given internal-orders. */
    readonly generateOrderExport: FileExportInputResponse
    /** Generate a file-export for the voucher usage for the given client and datespan. */
    readonly generateVoucherUsageExport: FileExportInputResponse
    readonly login: AuthPayload
    /** @deprecated Use authorizeClient instead */
    readonly loginWithShortcode: AuthPayload
    readonly logout: LogoutResponse
    readonly pickUpOrder: Order
    readonly publishMenu: Menu
    readonly refreshToken: RefreshTokenPayload
    readonly register: RegisterResponse
    readonly savePurchaseOrder: PurchaseOrder
    readonly sendResetPasswordEmail: Scalars['Boolean']['output']
    readonly sendResetPasswordEmailToUser: Scalars['Boolean']['output']
    readonly setOrderStatus: OrderStatusUpdateReport
    readonly shortcodeRegister: RegisterResponse
    readonly sortMenuEntries: ReadonlyArray<MenuEntry>
    /**
   * Takes a restaurant ID and a date as a scope for a sortable set of occurrences, and then a
   * list of positions matching each occurrence (by ID) with a position. It saves the given positions
   * to the system. Note that ALL occurrences that associate with the given restaurant and date must
   * be represented in the positions list, otherwise the mutation will fail.
   */
    readonly sortMenuEntryOccurrences: ReadonlyArray<MenuEntryOccurrence>
    readonly sortTemplateMenuEntries: ReadonlyArray<TemplateMenuEntry>
    readonly tagMenuEntries: ReadonlyArray<MenuEntryTagResult>
    readonly twoFactorAuthenticationConfirm: ReadonlyArray<Scalars['String']['output']>
    readonly twoFactorAuthenticationDisable?: Maybe<Scalars['Boolean']['output']>
    readonly twoFactorAuthenticationEnable: TwoFactorSecret
    readonly updateAllergen?: Maybe<Allergen>
    readonly updateClient: Client
    readonly updateForgottenPassword: ForgotPasswordResponse
    readonly updateInternalVoucher?: Maybe<InternalVoucher>
    readonly updateLocation: Location
    readonly updateMe: User
    /**
   * Updates the tag by given ID with the given name and HEX color values.
   * The given name must be unique.
   */
    readonly updateMenuEntryTag: MenuEntryTag
    readonly updateNewsItem: NewsItem
    /**
   * Updates the prices of a menu-entry-occurrence. For each client that is served by the occurrence's restaurant
   * exactly one price input is required.
   */
    readonly updateOccurrencePrices: MenuEntryOccurrence
    readonly updateOfficeDays: ReadonlyArray<OfficeDay>
    readonly updatePassword: UpdatePasswordResponse
    readonly updatePoll: Question
    readonly updateProduct: Product
    readonly updateProductCategory?: Maybe<ProductCategory>
    readonly updatePublicCategory: PublicCategory
    readonly updateRestaurant: Restaurant
    readonly updateRestaurantOpeningHours: Restaurant
    readonly updateRestaurantTimeslots: RestaurantTimeslotConfiguration
    readonly updateStock: UpdateStockResponse
    readonly updateTemplateMenuEntries: ReadonlyArray<TemplateMenuEntry>
    readonly updateUser: User
    readonly updateUserAllergens: User
    readonly verifyEmail: AuthPayload
    readonly verifyPayment: Payment
}


export interface MutationAssignClientsToRestaurantArgs {
    associations: ReadonlyArray<RestaurantClientAssociationInput>
    restaurantId: Scalars['ID']['input']
}


export interface MutationAssignProductCategoriesToRestaurantArgs {
    input: AssignProductCategoriesToRestaurantInput
}


export interface MutationAssignProductCategoryPricesToClientArgs {
    input: AssignProductCategoryPricesToClientInput
}


export interface MutationAssignRestaurantMenuArgs {
    isoWeekNumber: Scalars['IsoWeekNumber']['input']
    isoWeekYear: Scalars['IsoWeekYear']['input']
    occurrences: ReadonlyArray<MenuEntryOccurrenceInput>
    restaurantId: Scalars['ID']['input']
}


export interface MutationAssignRestaurantsToClientArgs {
    associations: ReadonlyArray<ClientRestaurantAssociationInput>
    clientId: Scalars['ID']['input']
}


export interface MutationAssociateExternalCategoryArgs {
    externalCategory: Scalars['ID']['input']
    privateCategory: Scalars['ID']['input']
}


export interface MutationAuthorizeClientArgs {
    input: AuthorizeClientInput
}


export interface MutationCreateAnswerArgs {
    input: CreateAnswerInput
}


export interface MutationCreateClientArgs {
    input: CreateClientInput
}


export interface MutationCreateContentMediaArgs {
    media: Scalars['Upload']['input']
}


export interface MutationCreateDishRatingArgs {
    input: CreateDishRatingInput
}


export interface MutationCreateDismissRatingArgs {
    input: CreateDismissRatingInput
}


export interface MutationCreateDomainsArgs {
    input: CreateDomainInput
}


export interface MutationCreateInternalVoucherArgs {
    input: CreateInternalVoucherInput
}


export interface MutationCreateLocationArgs {
    input: CreateLocationInput
}


export interface MutationCreateLoginSessionArgs {
    input: CreateLoginSessionInput
}


export interface MutationCreateMenuEntriesArgs {
    entries: ReadonlyArray<MenuEntryCreationInput>
}


export interface MutationCreateMenuEntryTagArgs {
    hexColor: Scalars['String']['input']
    name: Scalars['String']['input']
}


export interface MutationCreateNewsItemArgs {
    input: NewsItemInput
}


export interface MutationCreateOrUpdateClientThemeArgs {
    clientId: Scalars['ID']['input']
    input?: InputMaybe<UpdateClientThemeInput>
}


export interface MutationCreateOrdersArgs {
    orders: ReadonlyArray<OrderCreationInput>
}


export interface MutationCreatePaymentArgs {
    orderIds: ReadonlyArray<Scalars['ID']['input']>
    vouchers?: InputMaybe<ReadonlyArray<VoucherUsageInput>>
}


export interface MutationCreatePollArgs {
    input: CreatePollInput
}


export interface MutationCreateProductArgs {
    input: CreateProductInput
}


export interface MutationCreateProductCategoryArgs {
    input: CreateProductCategoryInput
}


export interface MutationCreatePublicCategoryArgs {
    input: CreatePublicCategoryInput
}


export interface MutationCreateRestaurantArgs {
    input: CreateRestaurantInput
}


export interface MutationCreateTemplateMenuArgs {
    restaurantId: Scalars['ID']['input']
}


export interface MutationCreateTemplateMenuEntriesArgs {
    entries: ReadonlyArray<TemplateMenuEntryCreationInput>
}


export interface MutationCreateUserArgs {
    input: CreateUserInput
}


export interface MutationDeleteClientArgs {
    id: Scalars['ID']['input']
}


export interface MutationDeleteDomainArgs {
    id: Scalars['ID']['input']
}


export interface MutationDeleteFileExportArgs {
    id: Scalars['ID']['input']
}


export interface MutationDeleteInternalVoucherArgs {
    id: Scalars['ID']['input']
}


export interface MutationDeleteMenuEntriesArgs {
    ids: ReadonlyArray<Scalars['ID']['input']>
}


export interface MutationDeleteMenuEntryOccurrenceArgs {
    id: Scalars['ID']['input']
}


export interface MutationDeleteMenuEntryTagArgs {
    id: Scalars['ID']['input']
}


export interface MutationDeleteNewsItemArgs {
    id: Scalars['ID']['input']
}


export interface MutationDeletePollArgs {
    id: Scalars['ID']['input']
}


export interface MutationDeleteProductArgs {
    id: Scalars['ID']['input']
}


export interface MutationDeleteTemplateMenuArgs {
    id: Scalars['ID']['input']
}


export interface MutationDeleteTemplateMenuEntriesArgs {
    ids: ReadonlyArray<Scalars['ID']['input']>
}


export interface MutationDeleteUserArgs {
    id: Scalars['ID']['input']
}


export interface MutationDisableTwoFactorAuthenticationArgs {
    userId: Scalars['ID']['input']
}


export interface MutationFinishLoginSessionArgs {
    input: FinishLoginSessionInput
}


export interface MutationForgotPasswordArgs {
    email: Scalars['String']['input']
}


export interface MutationGenerateDishRatingAveragesExportArgs {
    input: DishRatingAveragesInput
}


export interface MutationGenerateDishRatingDetailExportArgs {
    input: DishRatingsDetailExportInput
}


export interface MutationGenerateDishRatingsExportArgs {
    input: DishRatingsExportInput
}


export interface MutationGenerateFeedbackExportArgs {
    input: FeedbackExportInput
}


export interface MutationGenerateFinancialOrdersExportArgs {
    input: FinancialOrdersExportInput
}


export interface MutationGenerateOrderExportArgs {
    input: OrderExportInput
}


export interface MutationGenerateVoucherUsageExportArgs {
    input: VoucherUsageExportInput
}


export interface MutationLoginArgs {
    input?: InputMaybe<LoginInput>
}


export interface MutationLoginWithShortcodeArgs {
    input: LoginWithShortcodeInput
}


export interface MutationPickUpOrderArgs {
    input: PickUpOrderInput
}


export interface MutationPublishMenuArgs {
    id: Scalars['ID']['input']
}


export interface MutationRefreshTokenArgs {
    input?: InputMaybe<RefreshTokenInput>
}


export interface MutationRegisterArgs {
    input?: InputMaybe<RegisterInput>
}


export interface MutationSavePurchaseOrderArgs {
    input: SavePurchaseOrderInput
}


export interface MutationSendResetPasswordEmailArgs {
    input: SendResetPasswordEmailInput
}


export interface MutationSendResetPasswordEmailToUserArgs {
    user: Scalars['ID']['input']
}


export interface MutationSetOrderStatusArgs {
    orderID: Scalars['ID']['input']
    status: OrderStatusEnum
}


export interface MutationShortcodeRegisterArgs {
    input?: InputMaybe<ShortcodeRegisterInput>
}


export interface MutationSortMenuEntriesArgs {
    date: Scalars['Date']['input']
    positions: ReadonlyArray<MenuEntryPositioningInput>
}


export interface MutationSortMenuEntryOccurrencesArgs {
    date: Scalars['Date']['input']
    positions: ReadonlyArray<MenuEntryOccurrencePositioningInput>
    restaurantId: Scalars['ID']['input']
}


export interface MutationSortTemplateMenuEntriesArgs {
    positions: ReadonlyArray<TemplateMenuEntryPositionInput>
    templateMenuId: Scalars['ID']['input']
}


export interface MutationTagMenuEntriesArgs {
    entries: ReadonlyArray<Scalars['ID']['input']>
    tagStrategy: TagStrategyEnum
    tags: ReadonlyArray<Scalars['ID']['input']>
}


export interface MutationTwoFactorAuthenticationConfirmArgs {
    input?: InputMaybe<TwoFactorAuthenticationConfirmInput>
}


export interface MutationTwoFactorAuthenticationEnableArgs {
    input?: InputMaybe<TwoFactorAuthenticationEnableInput>
}


export interface MutationUpdateAllergenArgs {
    input: UpdateAllergenInput
}


export interface MutationUpdateClientArgs {
    input: UpdateClientInput
}


export interface MutationUpdateForgottenPasswordArgs {
    input?: InputMaybe<NewPasswordWithCodeInput>
}


export interface MutationUpdateInternalVoucherArgs {
    input: UpdateInternalVoucherInput
}


export interface MutationUpdateLocationArgs {
    input: UpdateLocationInput
}


export interface MutationUpdateMeArgs {
    input: UpdateMeInput
}


export interface MutationUpdateMenuEntryTagArgs {
    hexColor: Scalars['String']['input']
    id: Scalars['ID']['input']
    name: Scalars['String']['input']
}


export interface MutationUpdateNewsItemArgs {
    id?: InputMaybe<Scalars['ID']['input']>
    input: NewsItemInput
}


export interface MutationUpdateOccurrencePricesArgs {
    menuEntryOccurrenceId: Scalars['ID']['input']
    prices: ReadonlyArray<MenuEntryOccurrencePriceInput>
}


export interface MutationUpdateOfficeDaysArgs {
    input: ChangeOfficeDaysInput
}


export interface MutationUpdatePasswordArgs {
    input: UpdatePassword
}


export interface MutationUpdatePollArgs {
    input: UpdatePollInput
}


export interface MutationUpdateProductArgs {
    input: UpdateProductInput
}


export interface MutationUpdateProductCategoryArgs {
    input: UpdateProductCategoryInput
}


export interface MutationUpdatePublicCategoryArgs {
    input: UpdatePublicCategoryInput
}


export interface MutationUpdateRestaurantArgs {
    input: UpdateRestaurantInput
}


export interface MutationUpdateRestaurantOpeningHoursArgs {
    openingHours: ReadonlyArray<RestaurantOpeningHoursInput>
    restaurantId: Scalars['ID']['input']
}


export interface MutationUpdateRestaurantTimeslotsArgs {
    config?: InputMaybe<RestaurantTimeslotSettingsInput>
    hasTimeslots: Scalars['Boolean']['input']
    restaurantId: Scalars['ID']['input']
}


export interface MutationUpdateStockArgs {
    menuEntryOccurrenceID: Scalars['ID']['input']
    mutationQuantity: Scalars['Int']['input']
    mutationType: StockMutationTypeEnum
}


export interface MutationUpdateTemplateMenuEntriesArgs {
    entries: ReadonlyArray<TemplateMenuEntryUpdateInput>
    templateMenuId: Scalars['ID']['input']
}


export interface MutationUpdateUserArgs {
    input: UpdateUserInput
}


export interface MutationUpdateUserAllergensArgs {
    input: UpdateUserAllergensInput
}


export interface MutationVerifyEmailArgs {
    input: VerifyEmailInput
}


export interface MutationVerifyPaymentArgs {
    id: Scalars['ID']['input']
}

export interface NewPasswordWithCodeInput {
    readonly email: Scalars['String']['input']
    readonly password: Scalars['String']['input']
    readonly password_confirmation: Scalars['String']['input']
    readonly token: Scalars['String']['input']
}

/** Deprecated(reason: "Will be removed. Use the `NewsItem` type and related operations instead.") */
export interface NewsArticle {
    readonly body?: Maybe<Scalars['String']['output']>
    readonly id: Scalars['ID']['output']
    readonly image?: Maybe<Scalars['String']['output']>
    readonly newsPage?: Maybe<NewsPage>
    readonly position?: Maybe<Scalars['String']['output']>
    readonly title?: Maybe<Scalars['String']['output']>
}


/** Deprecated(reason: "Will be removed. Use the `NewsItem` type and related operations instead.") */
export interface NewsArticleImageArgs {
    variant?: InputMaybe<NewsArticleImageVariant>
}

/** Allowed resizes for the `variant` argument on the query `image`. */
export enum NewsArticleImageVariant {
    Original = 'ORIGINAL',
}

export interface NewsItem {
    readonly clients: ReadonlyArray<Client>
    readonly content?: Maybe<Scalars['String']['output']>
    readonly contentMedia: ReadonlyArray<ContentMedia>
    readonly createdAt: Scalars['DateTime']['output']
    readonly highlightedImage?: Maybe<ContentMedia>
    readonly id: Scalars['ID']['output']
    readonly intro?: Maybe<Scalars['String']['output']>
    readonly publishedAt?: Maybe<Scalars['DateTime']['output']>
    readonly title?: Maybe<Scalars['String']['output']>
    readonly translations: ReadonlyArray<NewsItemTranslation>
    readonly updatedAt: Scalars['DateTime']['output']
}

export interface NewsItemInput {
    readonly clients: ReadonlyArray<Scalars['ID']['input']>
    readonly contentMedia: ReadonlyArray<Scalars['ID']['input']>
    readonly highlightedImage?: InputMaybe<Scalars['ID']['input']>
    readonly publishedAt?: InputMaybe<Scalars['DateTime']['input']>
    readonly translations: ReadonlyArray<NewsItemTranslationInput>
}

export interface NewsItemTranslation {
    readonly content?: Maybe<Scalars['String']['output']>
    readonly intro?: Maybe<Scalars['String']['output']>
    readonly locale: Scalars['String']['output']
    readonly title?: Maybe<Scalars['String']['output']>
}

export interface NewsItemTranslationInput {
    readonly content?: InputMaybe<Scalars['String']['input']>
    readonly intro?: InputMaybe<Scalars['String']['input']>
    readonly locale: Scalars['String']['input']
    readonly title?: InputMaybe<Scalars['String']['input']>
}

export interface NewsItemsInput {
    readonly clients?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly endDateTime?: InputMaybe<Scalars['DateTime']['input']>
    readonly pagination: PaginationInput
    readonly searchQuery?: InputMaybe<Scalars['String']['input']>
    readonly sorting?: InputMaybe<SortingInput>
    readonly startDateTime?: InputMaybe<Scalars['DateTime']['input']>
}

export interface NewsItemsPaginator {
    readonly data: ReadonlyArray<NewsItem>
    readonly paginatorInfo: PaginatorInfo
}

/** Deprecated(reason: "Will be removed. Use the `NewsItem` type and related operations instead.") */
export interface NewsPage {
    readonly articles: ReadonlyArray<NewsArticle>
    readonly clients: ReadonlyArray<Client>
    readonly heroImage?: Maybe<Scalars['String']['output']>
    readonly id: Scalars['ID']['output']
    readonly intro: Scalars['String']['output']
    readonly published_at?: Maybe<Scalars['Date']['output']>
    readonly tagline?: Maybe<Scalars['String']['output']>
    readonly title: Scalars['String']['output']
}

/** News page sortable column enum */
export enum NewsPageSortableColumnEnum {
    /** Created at */
    CreatedAt = 'CREATED_AT',
    /** Published at */
    PublishedAt = 'PUBLISHED_AT',
}

/** Deprecated(reason: "Will be removed. Use the `NewsItem` type and related operations instead.") */
export interface NewsPagesInput {
    readonly date?: InputMaybe<Scalars['Date']['input']>
    readonly first: Scalars['Int']['input']
    readonly page?: InputMaybe<Scalars['Int']['input']>
}

/** Deprecated(reason: "Will be removed. Use the `NewsItem` type and related operations instead.") */
export interface NewsPagesPaginator {
    readonly data: ReadonlyArray<NewsPage>
    readonly paginatorInfo: PaginatorInfo
}

export interface Nutrient {
    readonly NutrientType?: Maybe<NutrientType>
    readonly ProductVersion?: Maybe<ProductVersion>
    readonly perHundredGram?: Maybe<Scalars['Float']['output']>
}

export interface NutrientType {
    readonly id: Scalars['ID']['output']
    readonly isMacro: Scalars['Boolean']['output']
    readonly name: Scalars['String']['output']
    readonly position: Scalars['Int']['output']
    readonly unit: NutrientTypeUnitEnum
}

/** Nutrient type unit enum */
export enum NutrientTypeUnitEnum {
    /** Gram */
    Gram = 'GRAM',
    /** Kcal */
    Kcal = 'KCAL',
    /** Kj */
    Kj = 'KJ',
}

export interface OfficeDay {
    readonly closingTime?: Maybe<Scalars['String']['output']>
    readonly dayOfTheWeek: WeekDayEnum
    readonly id: Scalars['ID']['output']
    readonly openingTime?: Maybe<Scalars['String']['output']>
    readonly user: User
}

/** Onboarding steps enum */
export enum OnboardingStepsEnum {
    /** Allergens */
    Allergens = 'ALLERGENS',
    /** Locations */
    Locations = 'LOCATIONS',
    /** None */
    None = 'None',
    /** Office days */
    OfficeDays = 'OFFICE_DAYS',
}

export interface OpeningHours {
    readonly closesAt: Scalars['String']['output']
    readonly opensAt: Scalars['String']['output']
}

export interface Order {
    readonly cityName?: Maybe<Scalars['String']['output']>
    readonly createdAt: Scalars['DateTime']['output']
    readonly expiredAt?: Maybe<Scalars['DateTime']['output']>
    readonly id: Scalars['ID']['output']
    readonly isOrderOnAccount: Scalars['Boolean']['output']
    readonly orderLines: ReadonlyArray<OrderLine>
    readonly paidAt?: Maybe<Scalars['DateTime']['output']>
    readonly payment?: Maybe<Payment>
    readonly pickedUpAt?: Maybe<Scalars['DateTime']['output']>
    readonly pickedUpOrderLineIds?: Maybe<ReadonlyArray<Scalars['ID']['output']>>
    readonly postalCode?: Maybe<Scalars['String']['output']>
    readonly reservedAt: Scalars['DateTime']['output']
    readonly restaurant: Restaurant
    readonly status: OrderStatusEnum
    readonly streetName?: Maybe<Scalars['String']['output']>
    readonly streetNumber?: Maybe<Scalars['String']['output']>
    readonly totalPrice: Scalars['Cent']['output']
    readonly transferableFrom: Scalars['DateTime']['output']
    readonly transferableUntil: Scalars['DateTime']['output']
    readonly updatedAt: Scalars['DateTime']['output']
    readonly user: User
}

export interface OrderBelongsTo {
    readonly connect: Scalars['ID']['input']
}

/** Allows ordering a list of records. */
export interface OrderByClause {
    /** The column that is used for ordering. */
    readonly column: Scalars['String']['input']
    /** The direction that is used for ordering. */
    readonly order: SortOrder
}

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
    /** Amount of items. */
    Count = 'COUNT',
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
    /** Average. */
    Avg = 'AVG',
    /** Amount of items. */
    Count = 'COUNT',
    /** Maximum. */
    Max = 'MAX',
    /** Minimum. */
    Min = 'MIN',
    /** Sum. */
    Sum = 'SUM',
}

/** The input type for the creation of one single order. */
export interface OrderCreationInput {
    /**
   * Provide a delivery address when appropriate.
   * This property is required if the restaurant is
   * of type delivery. It may be omitted otherwise.
   */
    readonly deliveryAddress?: InputMaybe<DeliveryAddressInput>
    readonly orderLines: ReadonlyArray<OrderLineCreationInput>
    readonly restaurantId: Scalars['ID']['input']
    /**
   * Provide the ID of a selected timeslot when appropriate.
   * This property is required if the restaurant is configured
   * to use timeslots. It may be omitted otherwise.
   */
    readonly timeslotId?: InputMaybe<Scalars['ID']['input']>
    readonly transferDate: Scalars['Date']['input']
}

export interface OrderExportInput {
    readonly endDate: Scalars['Date']['input']
    readonly restaurantId: Scalars['ID']['input']
    readonly startDate: Scalars['Date']['input']
}

export interface OrderFeedDay {
    readonly date: Scalars['Date']['output']
    readonly orders: ReadonlyArray<Order>
}

export interface OrderFeedFilterInput {
    readonly restaurant: Scalars['ID']['input']
}

export interface OrderFeedInput {
    readonly date: Scalars['Date']['input']
    readonly filter?: InputMaybe<OrderFeedFilterInput>
}

export interface OrderHistoryFilterConsumptionDateRangeInput {
    /** Format: yyyy-MM-dd */
    readonly endDate?: InputMaybe<Scalars['Date']['input']>
    /** Format: yyyy-MM-dd */
    readonly startDate?: InputMaybe<Scalars['Date']['input']>
}

export interface OrderHistoryFilterInput {
    readonly consumptionDateRange?: InputMaybe<OrderHistoryFilterConsumptionDateRangeInput>
    /** Defaults to false */
    readonly excludePickedUp?: InputMaybe<Scalars['Boolean']['input']>
    readonly restaurant?: InputMaybe<RestaurantBelongsTo>
}

export interface OrderHistoryInput {
    readonly filter?: InputMaybe<OrderHistoryFilterInput>
    readonly pagination: OrderHistoryPaginationInput
    readonly sorting: OrderHistorySortingEnum
}

export interface OrderHistoryPaginationInput {
    readonly first: Scalars['Int']['input']
    readonly page?: InputMaybe<Scalars['Int']['input']>
}

export interface OrderHistoryPaginator {
    readonly data: ReadonlyArray<Order>
    readonly paginatorInfo: PaginatorInfo
}

/** Order history sorting enum */
export enum OrderHistorySortingEnum {
    /** Consumption date desc */
    ConsumptionDateDesc = 'CONSUMPTION_DATE_DESC',
    /** Paid at desc */
    PaidAtDesc = 'PAID_AT_DESC',
}

export interface OrderLine {
    readonly additions: ReadonlyArray<Addition>
    readonly currentProductVersion?: Maybe<ProductVersion>
    readonly id: Scalars['ID']['output']
    readonly productVersion: ProductVersion
    readonly totalPrice: Scalars['Cent']['output']
    readonly vatTariffPercentage?: Maybe<Scalars['Int']['output']>
}

export interface OrderLineCreationInput {
    readonly additionIds: ReadonlyArray<Scalars['ID']['input']>
    readonly menuEntryOccurrenceId: Scalars['ID']['input']
    readonly quantity: Scalars['Int']['input']
}

export interface OrderReceipt {
    readonly status: OrderReceiptStatusEnum
}

/** Order receipt status enum */
export enum OrderReceiptStatusEnum {
    /** Failed */
    Failed = 'FAILED',
    /** Success */
    Success = 'SUCCESS',
}

/** Order state enum */
export enum OrderStateEnum {
    /** Failed */
    Failed = 'FAILED',
    /** Successful */
    Successful = 'SUCCESSFUL',
}

/** Order status enum */
export enum OrderStatusEnum {
    /** Canceled */
    Canceled = 'CANCELED',
    /** Failed */
    Failed = 'FAILED',
    /** Open */
    Open = 'OPEN',
    /** Paid */
    Paid = 'PAID',
    /** Picked up */
    PickedUp = 'PICKED_UP',
}

export interface OrderStatusUpdateReport {
    readonly nextStatus: OrderStatusEnum
    readonly orderID: Scalars['ID']['output']
    readonly prevStatus: OrderStatusEnum
}

/** Order strategy enum */
export enum OrderStrategyEnum {
    /** Disabled */
    Disabled = 'DISABLED',
    /** Order by payment */
    OrderByPayment = 'ORDER_BY_PAYMENT',
    /** Order on account */
    OrderOnAccount = 'ORDER_ON_ACCOUNT',
}

export interface OrdersInput {
    readonly clients?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly endDateTime: Scalars['DateTime']['input']
    readonly locations?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly pagination: PaginationInput
    readonly restaurants?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly startDateTime: Scalars['DateTime']['input']
    readonly states?: InputMaybe<ReadonlyArray<OrderStateEnum>>
}

export interface OrdersPaginator {
    readonly data: ReadonlyArray<Order>
    readonly paginatorInfo: PaginatorInfo
}

export interface PaginationInput {
    readonly first: Scalars['Int']['input']
    readonly page: Scalars['Int']['input']
}

/** Retrieved from: https://lighthouse-php.com/5/api-reference/directives.html#basic-usage */
export interface PaginatorInfo {
    /** Number of items in the current page. */
    readonly count: Scalars['Int']['output']
    /** Index of the current page. */
    readonly currentPage: Scalars['Int']['output']
    /** Index of the first item in the current page. */
    readonly firstItem?: Maybe<Scalars['Int']['output']>
    /** Are there more pages after this one? */
    readonly hasMorePages: Scalars['Boolean']['output']
    /** Index of the last item in the current page. */
    readonly lastItem?: Maybe<Scalars['Int']['output']>
    /** Index of the last available page. */
    readonly lastPage: Scalars['Int']['output']
    /** Number of items per page. */
    readonly perPage: Scalars['Int']['output']
    /** Number of total available items. */
    readonly total: Scalars['Int']['output']
}

export interface Payment {
    readonly amount: Scalars['Cent']['output']
    readonly checkoutUrl?: Maybe<Scalars['String']['output']>
    readonly currency: CurrencyEnum
    readonly feeAmount?: Maybe<Scalars['Cent']['output']>
    readonly id: Scalars['ID']['output']
    readonly method?: Maybe<PaymentMethodEnum>
    readonly molliePaymentId?: Maybe<Scalars['String']['output']>
    readonly orders: ReadonlyArray<Order>
    readonly refundedAmount: Scalars['Cent']['output']
    readonly status: PaymentStatusEnum
    readonly vouchers?: Maybe<ReadonlyArray<Voucher>>
}

export interface PaymentMethod {
    readonly key: PaymentMethodEnum
    readonly value: Scalars['String']['output']
}

/** Payment method enum */
export enum PaymentMethodEnum {
    /** Applepay */
    Applepay = 'APPLEPAY',
    /** Bill to client */
    BillToClient = 'BILL_TO_CLIENT',
    /** Credit card */
    CreditCard = 'CREDIT_CARD',
    /** Ideal */
    Ideal = 'IDEAL',
    /** Paypal */
    Paypal = 'PAYPAL',
}

/** Payment status enum */
export enum PaymentStatusEnum {
    /** Authorized */
    Authorized = 'AUTHORIZED',
    /** Canceled */
    Canceled = 'CANCELED',
    /** Expired */
    Expired = 'EXPIRED',
    /** Failed */
    Failed = 'FAILED',
    /** Open */
    Open = 'OPEN',
    /** Paid */
    Paid = 'PAID',
    /** Pending */
    Pending = 'PENDING',
}

export interface PaymentsInput {
    readonly first: Scalars['Int']['input']
    readonly page?: InputMaybe<Scalars['Int']['input']>
}

export interface PaymentsPaginator {
    readonly data: ReadonlyArray<Payment>
    readonly paginatorInfo: PaginatorInfo
}

export interface PickUpOrderInput {
    readonly orderId: Scalars['ID']['input']
    /** Empty, partial and complete list of IDs allowed */
    readonly orderLineIds: ReadonlyArray<Scalars['ID']['input']>
}

export interface PlatformStatistic {
    readonly downloads?: Maybe<ReadonlyArray<PlatformStatisticDownloadItem>>
    readonly orders?: Maybe<ReadonlyArray<PlatformStatisticOrderItem>>
    readonly users?: Maybe<ReadonlyArray<PlatformStatisticUserItem>>
}

export interface PlatformStatisticDownloadItem {
    readonly platform: Scalars['String']['output']
    readonly stats: PlatformStatisticItem
}

export interface PlatformStatisticItem {
    readonly percentage: Scalars['Float']['output']
    readonly periodAverage: Scalars['Float']['output']
    readonly periodValue: Scalars['Int']['output']
    readonly totalAverage: Scalars['Float']['output']
    readonly totalValue: Scalars['Int']['output']
}

export interface PlatformStatisticOrderItem {
    readonly stats: PlatformStatisticItem
    readonly type: Scalars['String']['output']
}

/** Platform statistic type enum */
export enum PlatformStatisticTypeEnum {
    /** Active users */
    ActiveUsers = 'ACTIVE_USERS',
    /** App downloads android */
    AppDownloadsAndroid = 'APP_DOWNLOADS_ANDROID',
    /** App downloads ios */
    AppDownloadsIos = 'APP_DOWNLOADS_IOS',
    /** Canceled orders */
    CanceledOrders = 'CANCELED_ORDERS',
    /** Failed orders */
    FailedOrders = 'FAILED_ORDERS',
    /** Picked up orders */
    PickedUpOrders = 'PICKED_UP_ORDERS',
    /** Successful orders */
    SuccessfulOrders = 'SUCCESSFUL_ORDERS',
}

export interface PlatformStatisticUserItem {
    readonly stats: PlatformStatisticItem
    readonly type: Scalars['String']['output']
}

export interface PlatformStatisticsInput {
    readonly endDate: Scalars['Date']['input']
    readonly startDate: Scalars['Date']['input']
}

export interface PollsInput {
    readonly clientIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly endDate: Scalars['Date']['input']
    readonly pagination: PaginationInput
    readonly pollType?: InputMaybe<QuestionTypeEnum>
    readonly searchQuery?: InputMaybe<Scalars['String']['input']>
    readonly startDate: Scalars['Date']['input']
}

export interface PollsPaginator {
    readonly data: ReadonlyArray<Question>
    readonly paginatorInfo: PaginatorInfo
}

export interface PricingAgreement {
    readonly client: Client
    readonly clientId: Scalars['ID']['output']
    readonly clientPrice?: Maybe<Scalars['Cent']['output']>
    readonly id: Scalars['ID']['output']
    readonly productCategory: ProductCategory
    readonly productCategoryId: Scalars['ID']['output']
    readonly sellingPrice?: Maybe<Scalars['Cent']['output']>
}

export interface PricingAgreementsQueryFilter {
    readonly clients?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly productCategories?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
}

export interface PrivateCategoryOverview {
    readonly date: Scalars['Date']['output']
    readonly privateCategoryId: Scalars['ID']['output']
    readonly privateCategoryName: Scalars['String']['output']
    readonly totalQuantity: Scalars['Int']['output']
}

export interface Product {
    readonly category: ProductCategory
    readonly createdAt: Scalars['DateTime']['output']
    readonly currentVersion: ProductVersion
    readonly dietaryClass?: Maybe<Scalars['String']['output']>
    readonly externalRecipeId?: Maybe<Scalars['String']['output']>
    readonly id: Scalars['ID']['output']
    readonly isOnMenu: Scalars['Boolean']['output']
    readonly name: Scalars['String']['output']
    readonly publicCategory?: Maybe<PublicCategory>
    readonly source: ItemSourceEnum
    readonly tagline?: Maybe<Scalars['String']['output']>
    readonly tags: ReadonlyArray<Tag>
    readonly type: ProductTypeEnum
    readonly updatedAt: Scalars['DateTime']['output']
    readonly vatTariffPercentage?: Maybe<Scalars['Int']['output']>
    readonly versions: ReadonlyArray<ProductVersion>
}

export interface ProductCategoriesFilterInput {
    readonly clientId?: InputMaybe<Scalars['ID']['input']>
    readonly locationId?: InputMaybe<Scalars['ID']['input']>
    readonly restaurantId?: InputMaybe<Scalars['ID']['input']>
}

export interface ProductCategoriesInput {
    readonly filters?: InputMaybe<ProductCategoryFiltersInput>
    readonly first: Scalars['Int']['input']
    readonly page?: InputMaybe<Scalars['Int']['input']>
}

export interface ProductCategoriesPaginator {
    readonly data: ReadonlyArray<ProductCategory>
    readonly paginatorInfo: PaginatorInfo
}

/** Product categories scope type enum */
export enum ProductCategoriesScopeTypeEnum {
    /** Client */
    Client = 'CLIENT',
    /** Location */
    Location = 'LOCATION',
    /** Restaurant */
    Restaurant = 'RESTAURANT',
}

export interface ProductCategory {
    readonly externalCategories: ReadonlyArray<ExternalCategory>
    readonly id: Scalars['ID']['output']
    readonly name: Scalars['String']['output']
    /** @deprecated This field no longer exists in the database. */
    readonly position?: Maybe<Scalars['Int']['output']>
    readonly publicCategory?: Maybe<PublicCategory>
    readonly restaurants: ReadonlyArray<Restaurant>
}

export interface ProductCategoryBelongsTo {
    readonly connect: Scalars['ID']['input']
}

export interface ProductCategoryFiltersInput {
    readonly clients?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly hasPublicCategory?: InputMaybe<Scalars['Boolean']['input']>
    readonly locations?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly publicCategories?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly query?: InputMaybe<Scalars['String']['input']>
    readonly restaurants?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
}

export interface ProductCategoryPrice {
    readonly client: Client
    readonly clientPrice?: Maybe<Scalars['Cent']['output']>
    readonly id: Scalars['ID']['output']
    readonly productCategory: ProductCategory
    readonly sellingPrice?: Maybe<Scalars['Cent']['output']>
}

export interface ProductCategoryPriceInput {
    readonly clientPrice: Scalars['Cent']['input']
    readonly productCategoryId: Scalars['ID']['input']
    readonly sellingPrice: Scalars['Cent']['input']
}

export interface ProductFiltersInput {
    readonly categories?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly query?: InputMaybe<Scalars['String']['input']>
    readonly types?: InputMaybe<ReadonlyArray<ProductTypeEnum>>
}

export interface ProductRejectsInput {
    /** Optionally provide a list of IDs to **exclude** from the list of results */
    readonly ids?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
}

export interface ProductSalesEntry {
    readonly additions: ReadonlyArray<AdditionSalesEntry>
    readonly amountClaimed: Scalars['Int']['output']
    readonly amountIssued: Scalars['Int']['output']
    readonly amountReserved: Scalars['Int']['output']
    readonly product: Product
}

export interface ProductSalesFilters {
    readonly clients?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly restaurants?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly states?: InputMaybe<ReadonlyArray<OrderStateEnum>>
}

export interface ProductSalesPage {
    readonly data: ReadonlyArray<ProductSalesEntry>
    readonly paginatorInfo: PaginatorInfo
}

/** Product type enum */
export enum ProductTypeEnum {
    /** Dessert */
    Dessert = 'DESSERT',
    /** Drink */
    Drink = 'DRINK',
    /** Meal */
    Meal = 'MEAL',
    /** Side dish */
    SideDish = 'SIDE_DISH',
}

export interface ProductVersion {
    readonly additions: ReadonlyArray<Addition>
    readonly allergens: ReadonlyArray<Allergen>
    readonly createdAt: Scalars['DateTime']['output']
    readonly description?: Maybe<Scalars['String']['output']>
    /** @deprecated Will be removed in V5. */
    readonly endsAt?: Maybe<Scalars['Date']['output']>
    readonly id: Scalars['ID']['output']
    readonly image?: Maybe<ProductVersionImage>
    readonly marginPercentage: Scalars['Float']['output']
    readonly nutrients: ReadonlyArray<Nutrient>
    readonly price: Scalars['Cent']['output']
    readonly product: Product
    readonly shortDescription?: Maybe<Scalars['String']['output']>
    /** @deprecated Will be removed in V5. Use `createdAt` instead. */
    readonly startsAt: Scalars['Date']['output']
}

export interface ProductVersionBelongsTo {
    readonly connect: Scalars['ID']['input']
}

export interface ProductVersionImage {
    readonly variants: ProductVersionImages
}

/** Product version image variant enum */
export enum ProductVersionImageVariantEnum {
    /** Original */
    Original = 'ORIGINAL',
    /** Square 150 */
    Square_150 = 'SQUARE_150',
    /** Square 300 */
    Square_300 = 'SQUARE_300',
    /** Square 600 */
    Square_600 = 'SQUARE_600',
}

export interface ProductVersionImages {
    readonly ORIGINAL: Scalars['String']['output']
    readonly SQUARE_150: Scalars['String']['output']
    readonly SQUARE_300: Scalars['String']['output']
    readonly SQUARE_600: Scalars['String']['output']
}

export interface ProductVersionImagesInput {
    readonly productVersionId: Scalars['ID']['input']
}

export interface ProductsInput {
    readonly filters?: InputMaybe<ProductFiltersInput>
    readonly first: Scalars['Int']['input']
    readonly page?: InputMaybe<Scalars['Int']['input']>
    readonly rejects?: InputMaybe<ProductRejectsInput>
    readonly sorting?: InputMaybe<ReadonlyArray<SortingInput>>
}

export interface ProductsPaginator {
    readonly data: ReadonlyArray<Product>
    readonly paginatorInfo: PaginatorInfo
}

/** Products sortable column enum */
export enum ProductsSortableColumnEnum {
    /** Updated at */
    UpdatedAt = 'UPDATED_AT',
}

export interface PublicCategoriesInput {
    readonly filters?: InputMaybe<PublicCategoryFiltersInput>
    readonly first: Scalars['Int']['input']
    readonly page?: InputMaybe<Scalars['Int']['input']>
}

export interface PublicCategoriesPaginator {
    readonly data: ReadonlyArray<PublicCategory>
    readonly paginatorInfo: PaginatorInfo
}

export interface PublicCategory {
    readonly createdAt: Scalars['DateTime']['output']
    readonly id: Scalars['ID']['output']
    readonly name: Scalars['String']['output']
    readonly position: Scalars['Int']['output']
    readonly productCategories: ReadonlyArray<ProductCategory>
    readonly updatedAt: Scalars['DateTime']['output']
}

export interface PublicCategoryFiltersInput {
    readonly clients?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly locations?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly query?: InputMaybe<Scalars['String']['input']>
    readonly restaurants?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
}

export interface PurchaseOrder {
    readonly createdAt: Scalars['DateTime']['output']
    readonly id: Scalars['ID']['output']
    readonly isoWeekNumber: Scalars['IsoWeekNumber']['output']
    readonly isoWeekYear: Scalars['IsoWeekYear']['output']
    readonly purchaseOrderLines: ReadonlyArray<PurchaseOrderLine>
    readonly restaurant: Restaurant
    readonly status: PurchaseOrderStatusEnum
    readonly submittedAt?: Maybe<Scalars['DateTime']['output']>
    readonly submittedBy?: Maybe<User>
    readonly updatedAt: Scalars['DateTime']['output']
}

export interface PurchaseOrderDayOverview {
    readonly date: Scalars['Date']['output']
    readonly privateCategoriesOverview: ReadonlyArray<PrivateCategoryOverview>
    readonly purchaseOrdersOverview: ReadonlyArray<PurchaseOrderOverview>
}

export interface PurchaseOrderLine {
    readonly createdAt: Scalars['DateTime']['output']
    readonly id: Scalars['ID']['output']
    readonly isoWeekday: Scalars['IsoDayNumber']['output']
    readonly productCategory: ProductCategory
    readonly purchaseOrder: PurchaseOrder
    readonly quantity: Scalars['Int']['output']
    readonly updatedAt: Scalars['DateTime']['output']
}

export interface PurchaseOrderLineOverview {
    readonly privateCategoryId: Scalars['ID']['output']
    readonly privateCategoryName: Scalars['String']['output']
    readonly quantity: Scalars['Int']['output']
}

export interface PurchaseOrderOverview {
    readonly purchaseOrderLinesOverview: ReadonlyArray<PurchaseOrderLineOverview>
    readonly restaurantId: Scalars['ID']['output']
    readonly restaurantName: Scalars['String']['output']
    readonly serviceReferenceId: Scalars['String']['output']
}

export enum PurchaseOrderStatusEnum {
    Draft = 'DRAFT',
    PastDue = 'PAST_DUE',
    Submitted = 'SUBMITTED',
}

export interface PurchaseOrdersInput {
    readonly isoWeekNumber: Scalars['IsoWeekNumber']['input']
    readonly isoWeekYear: Scalars['IsoWeekYear']['input']
    readonly restaurantIds: ReadonlyArray<Scalars['ID']['input']>
}

export interface PurchaseOrdersOverviewInput {
    readonly isoWeekNumber: Scalars['IsoWeekNumber']['input']
    readonly isoWeekYear: Scalars['IsoWeekYear']['input']
    readonly privateCategoryIds: ReadonlyArray<Scalars['ID']['input']>
    readonly restaurantIds: ReadonlyArray<Scalars['ID']['input']>
}

export interface Query {
    readonly allergen?: Maybe<Allergen>
    readonly allergens: ReadonlyArray<Allergen>
    readonly answers: ReadonlyArray<Answer>
    readonly apiVersion: ApiVersion
    readonly appCompatibility: AppCompatibilityStatus
    readonly client: Client
    readonly clientSuggestion?: Maybe<Client>
    readonly clients: ClientsPaginator
    readonly contentMedia: ContentMedia
    readonly contentMedias: ContentMediaPaginator
    /**
   * Get the menu for a single day. Takes the mandatory arguments 'clientID' and 'date' to filter
   * the menu by. The result set may be further filtered using the optional 'filters' argument.
   * Returns null if no data matches the given filters.
   */
    readonly dailyMenu?: Maybe<DailyMenu>
    readonly dailyMenuItem: DailyMenuItem
    readonly dietaryClasses: ReadonlyArray<DietaryClass>
    /**
   * Get a list of average dish-ratings for many products at once. Only ratings for items that were
   * served between the given start- and end-dates (inclusive) add to the calculated average rating.
   */
    readonly dishRatingAverages: DishRatingAveragesPaginator
    /** Paginate a certain set of dish-ratings. */
    readonly dishRatings: DishRatingsPaginator
    /**
   * Get the total number of responses from feedback_dish_ratings table that match the given filters.
   * Note that this is different than what you get from dishRatingAverages
   */
    readonly dishRatingsAverage: Scalars['Float']['output']
    /**
   * Get the total number of responses from feedback_dish_ratings table that match the given filters.
   * Note that this is different from total results that you get from DishRatingAveragesPaginator
   */
    readonly dishRatingsCount: Scalars['Int']['output']
    readonly externalCategories: ReadonlyArray<ExternalCategory>
    readonly fileExport?: Maybe<FileExport>
    readonly fileExports?: Maybe<FileExportsPaginator>
    readonly financeDataForPayments: ReadonlyArray<FinanceDataForPayment>
    readonly financeDataForProductCategories: ReadonlyArray<FinanceDataForProductCategory>
    readonly financeDataForProductTypes: ReadonlyArray<FinanceDataForProductType>
    readonly financeDataSummary?: Maybe<ReadonlyArray<FinanceDataForSummary>>
    readonly internalVouchers?: Maybe<InternalVouchersPaginator>
    readonly isRatedByUser: Scalars['Boolean']['output']
    readonly latestAppVersions: LatestAppVersion
    /** @deprecated Will be removed. Use the `NewsItem` type and related operations instead. */
    readonly latestNewsPage: NewsPage
    /**
   * Get a list of published MenuEntryOccurrence records for the restaurant by given ID
   * on the given date. Returns null if the restaurant of given ID is closed on the
   * given date, or if a menu for the given date is not yet published.
   */
    readonly liveMenu?: Maybe<ReadonlyArray<MenuEntryOccurrence>>
    readonly locales: ReadonlyArray<Locale>
    readonly location: Location
    readonly locations: LocationsPaginator
    readonly me: User
    /** Fetches a list of menu entries for the given week parameters and (optional) filters. */
    readonly menuEntries: ReadonlyArray<MenuEntry>
    readonly menuEntry?: Maybe<MenuEntry>
    readonly menuEntryOccurrences: ReadonlyArray<MenuEntryOccurrence>
    /** Get the list of all existing menu-entry tags. */
    readonly menuEntryTags: ReadonlyArray<MenuEntryTag>
    /**
   * Get the internal representation of a weekly menu. This is only for administration tasks!
   * If a menu for the given week parameters does not yet exist one will be created and returned afterwards.
   */
    readonly menuInternal: Menu
    readonly newProductCategories: ProductCategoriesPaginator
    readonly newsItem: NewsItem
    readonly newsItems: NewsItemsPaginator
    /** @deprecated Will be removed. Use the `NewsItem` type and related operations instead. */
    readonly newsPage: NewsPage
    /** @deprecated Will be removed. Use the `NewsItem` type and related operations instead. */
    readonly newsPages?: Maybe<NewsPagesPaginator>
    readonly nutrientType?: Maybe<NutrientType>
    readonly nutrientTypeUnits: ReadonlyArray<NutrientTypeUnitEnum>
    readonly nutrientTypes: ReadonlyArray<NutrientType>
    readonly order: Order
    /**
   * An order overview created for kitchen-screens
   * Input requires a date and restaurant id
   */
    readonly orderFeed: OrderFeedDay
    /** @deprecated Let's work towards the situation where the orders query can fulfil these requests. It might have to implement sorting options first. */
    readonly orderHistory: OrdersPaginator
    readonly orders: OrdersPaginator
    readonly payment: Payment
    readonly paymentMethods: ReadonlyArray<PaymentMethod>
    readonly payments: PaymentsPaginator
    readonly platformStatistics: PlatformStatistic
    readonly poll: Question
    readonly polls: PollsPaginator
    readonly pricingAgreements: ReadonlyArray<PricingAgreement>
    readonly product?: Maybe<Product>
    readonly productCategories: ProductCategoriesPaginator
    readonly productCategory: ProductCategory
    readonly productCategoryPricesForCategory: ReadonlyArray<ProductCategoryPrice>
    readonly productCategoryPricesForClient: ReadonlyArray<ProductCategoryPrice>
    readonly productSales: ProductSalesPage
    readonly productVersionImages: ProductVersionImages
    readonly products: ProductsPaginator
    readonly publicCategories: PublicCategoriesPaginator
    readonly publicCategory: PublicCategory
    readonly purchaseOrders: ReadonlyArray<PurchaseOrder>
    readonly purchaseOrdersOverview: ReadonlyArray<PurchaseOrderDayOverview>
    readonly question: Question
    readonly questions: ReadonlyArray<Question>
    readonly rateableProducts: ReadonlyArray<RateableProduct>
    readonly requestOrderReceipt: OrderReceipt
    readonly restaurant: Restaurant
    readonly restaurantOrderStrategies: ReadonlyArray<RestaurantOrderStrategyWithModels>
    readonly restaurantTimeslotConfiguration: RestaurantTimeslotConfiguration
    readonly restaurants: RestaurantsPaginator
    readonly roles: ReadonlyArray<Role>
    readonly storableClientTheme?: Maybe<StorableClientTheme>
    readonly templateMenu?: Maybe<TemplateMenu>
    readonly templateMenus: ReadonlyArray<TemplateMenu>
    readonly timeslots: ReadonlyArray<Timeslot>
    /** @deprecated Use dishRatingsCount instead */
    readonly totalDishRatingAverages: Scalars['Int']['output']
    readonly user?: Maybe<User>
    readonly userStatuses: ReadonlyArray<UserStatus>
    readonly users?: Maybe<UsersPaginator>
    readonly vouchers?: Maybe<ReadonlyArray<Maybe<Voucher>>>
    readonly weekDayEnums: ReadonlyArray<WeekDayEnum>
}


export interface QueryAllergenArgs {
    id?: InputMaybe<Scalars['ID']['input']>
}


export interface QueryAnswersArgs {
    input: AnswersInput
}


export interface QueryAppCompatibilityArgs {
    appVersion: Scalars['Semver']['input']
    supportedApiRange: Scalars['SemverRange']['input']
}


export interface QueryClientArgs {
    id: Scalars['ID']['input']
}


export interface QueryClientSuggestionArgs {
    email?: InputMaybe<Scalars['String']['input']>
}


export interface QueryClientsArgs {
    input: ClientsInput
}


export interface QueryContentMediaArgs {
    id: Scalars['ID']['input']
}


export interface QueryContentMediasArgs {
    input: ContentMediasInput
}


export interface QueryDailyMenuArgs {
    clientID: Scalars['ID']['input']
    date: Scalars['Date']['input']
    filters?: InputMaybe<DailyMenuFilters>
}


export interface QueryDailyMenuItemArgs {
    menuEntryOccurrenceID: Scalars['ID']['input']
}


export interface QueryDishRatingAveragesArgs {
    categories?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    clients?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    endDate?: InputMaybe<Scalars['Date']['input']>
    pagination: PaginationInput
    searchQuery?: InputMaybe<Scalars['String']['input']>
    sorting: ReadonlyArray<SortingInput>
    startDate?: InputMaybe<Scalars['Date']['input']>
}


export interface QueryDishRatingsArgs {
    clients?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    endDate?: InputMaybe<Scalars['Date']['input']>
    pagination: PaginationInput
    products?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    restaurants?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    startDate?: InputMaybe<Scalars['Date']['input']>
}


export interface QueryDishRatingsAverageArgs {
    input?: InputMaybe<DishRatingsStatisticsInput>
}


export interface QueryDishRatingsCountArgs {
    input?: InputMaybe<DishRatingsStatisticsInput>
}


export interface QueryFileExportArgs {
    id: Scalars['ID']['input']
}


export interface QueryFileExportsArgs {
    pagination: PaginationInput
    statuses?: InputMaybe<ReadonlyArray<FileExportStatusEnum>>
    types?: InputMaybe<ReadonlyArray<FileExportTypeEnum>>
}


export interface QueryFinanceDataForPaymentsArgs {
    filters: FinanceDataFilterInput
}


export interface QueryFinanceDataForProductCategoriesArgs {
    filters: FinanceDataFilterInput
}


export interface QueryFinanceDataForProductTypesArgs {
    filters: FinanceDataFilterInput
}


export interface QueryFinanceDataSummaryArgs {
    filters: FinanceDataFilterInput
}


export interface QueryInternalVouchersArgs {
    input: InternalVouchersInput
}


export interface QueryIsRatedByUserArgs {
    input: IsRatedByUserInput
}


export interface QueryLiveMenuArgs {
    date: Scalars['Date']['input']
    restaurantID: Scalars['ID']['input']
}


export interface QueryLocationArgs {
    id: Scalars['ID']['input']
}


export interface QueryLocationsArgs {
    input: LocationsInput
}


export interface QueryMenuEntriesArgs {
    filter?: InputMaybe<MenuEntriesQueryFilter>
    isoWeekNumber: Scalars['IsoWeekNumber']['input']
    isoWeekYear: Scalars['IsoWeekYear']['input']
}


export interface QueryMenuEntryArgs {
    id: Scalars['ID']['input']
}


export interface QueryMenuEntryOccurrencesArgs {
    filter?: InputMaybe<MenuEntryOccurrencesQueryFilter>
    isoWeekNumber: Scalars['IsoWeekNumber']['input']
    isoWeekYear: Scalars['IsoWeekYear']['input']
}


export interface QueryMenuInternalArgs {
    isoWeekNumber: Scalars['IsoWeekNumber']['input']
    isoWeekYear: Scalars['IsoWeekYear']['input']
}


export interface QueryNewProductCategoriesArgs {
    input: ProductCategoriesInput
}


export interface QueryNewsItemArgs {
    id: Scalars['ID']['input']
}


export interface QueryNewsItemsArgs {
    input: NewsItemsInput
}


export interface QueryNewsPageArgs {
    id: Scalars['ID']['input']
}


export interface QueryNewsPagesArgs {
    input: NewsPagesInput
}


export interface QueryNutrientTypeArgs {
    id: Scalars['ID']['input']
}


export interface QueryOrderArgs {
    id: Scalars['ID']['input']
}


export interface QueryOrderFeedArgs {
    input?: InputMaybe<OrderFeedInput>
}


export interface QueryOrderHistoryArgs {
    input: OrderHistoryInput
}


export interface QueryOrdersArgs {
    input: OrdersInput
}


export interface QueryPaymentArgs {
    id: Scalars['ID']['input']
}


export interface QueryPaymentsArgs {
    input: PaymentsInput
}


export interface QueryPlatformStatisticsArgs {
    input?: InputMaybe<PlatformStatisticsInput>
}


export interface QueryPollArgs {
    id: Scalars['ID']['input']
}


export interface QueryPollsArgs {
    input: PollsInput
}


export interface QueryPricingAgreementsArgs {
    filter?: InputMaybe<PricingAgreementsQueryFilter>
}


export interface QueryProductArgs {
    id: Scalars['ID']['input']
}


export interface QueryProductCategoriesArgs {
    input: ProductCategoriesInput
}


export interface QueryProductCategoryArgs {
    id: Scalars['ID']['input']
}


export interface QueryProductCategoryPricesForCategoryArgs {
    categoryId: Scalars['ID']['input']
}


export interface QueryProductCategoryPricesForClientArgs {
    clientId: Scalars['ID']['input']
}


export interface QueryProductSalesArgs {
    consumedFrom: Scalars['DateTime']['input']
    consumedUntil: Scalars['DateTime']['input']
    filters?: InputMaybe<ProductSalesFilters>
    pagination: PaginationInput
}


export interface QueryProductVersionImagesArgs {
    input: ProductVersionImagesInput
}


export interface QueryProductsArgs {
    input: ProductsInput
}


export interface QueryPublicCategoriesArgs {
    input: PublicCategoriesInput
}


export interface QueryPublicCategoryArgs {
    id: Scalars['ID']['input']
}


export interface QueryPurchaseOrdersArgs {
    input: PurchaseOrdersInput
}


export interface QueryPurchaseOrdersOverviewArgs {
    input: PurchaseOrdersOverviewInput
}


export interface QueryQuestionArgs {
    id: Scalars['ID']['input']
}


export interface QueryQuestionsArgs {
    input: QuestionsInput
}


export interface QueryRequestOrderReceiptArgs {
    id: Scalars['ID']['input']
}


export interface QueryRestaurantArgs {
    id: Scalars['ID']['input']
}


export interface QueryRestaurantOrderStrategiesArgs {
    restaurantId: Scalars['ID']['input']
}


export interface QueryRestaurantTimeslotConfigurationArgs {
    restaurantId: Scalars['ID']['input']
}


export interface QueryRestaurantsArgs {
    input: RestaurantsInput
}


export interface QueryStorableClientThemeArgs {
    clientId: Scalars['ID']['input']
}


export interface QueryTemplateMenuArgs {
    restaurantId: Scalars['ID']['input']
}


export interface QueryTimeslotsArgs {
    input: TimeslotsInput
}


export interface QueryTotalDishRatingAveragesArgs {
    categories?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    clients?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    endDate?: InputMaybe<Scalars['Date']['input']>
    searchQuery?: InputMaybe<Scalars['String']['input']>
    startDate?: InputMaybe<Scalars['Date']['input']>
}


export interface QueryUserArgs {
    id?: InputMaybe<Scalars['ID']['input']>
}


export interface QueryUsersArgs {
    input?: InputMaybe<UsersFilterInput>
}


export interface QueryVouchersArgs {
    email: Scalars['String']['input']
}

export interface Question {
    readonly clients: ReadonlyArray<Client>
    readonly createdAt: Scalars['DateTime']['output']
    readonly endDate: Scalars['Date']['output']
    readonly feedback?: Maybe<Scalars['String']['output']>
    readonly id: Scalars['ID']['output']
    readonly placeholder?: Maybe<Scalars['String']['output']>
    readonly question: Scalars['String']['output']
    readonly startDate: Scalars['Date']['output']
    readonly translations: ReadonlyArray<QuestionTranslation>
    readonly type: QuestionTypeEnum
    readonly updatedAt: Scalars['DateTime']['output']
}

export interface QuestionClientInput {
    readonly id: Scalars['ID']['input']
    readonly sendNotification: Scalars['Boolean']['input']
    readonly showOnFeed: Scalars['Boolean']['input']
}

export interface QuestionTranslation {
    readonly feedback?: Maybe<Scalars['String']['output']>
    readonly locale: Scalars['String']['output']
    readonly placeholder?: Maybe<Scalars['String']['output']>
    readonly question: Scalars['String']['output']
}

export interface QuestionTranslationInput {
    readonly feedback?: InputMaybe<Scalars['String']['input']>
    readonly locale: Scalars['String']['input']
    readonly placeholder?: InputMaybe<Scalars['String']['input']>
    readonly question: Scalars['String']['input']
}

/** Question type enum */
export enum QuestionTypeEnum {
    /** Multiple choice */
    MultipleChoice = 'MULTIPLE_CHOICE',
    /** Open ended */
    OpenEnded = 'OPEN_ENDED',
    /** Smiley */
    Smiley = 'SMILEY',
    /** Star */
    Star = 'STAR',
}

export interface QuestionsInput {
    readonly date: Scalars['Date']['input']
}

export interface RateableProduct {
    readonly consumptionDate: Scalars['Date']['output']
    readonly order: Order
    readonly product: Product
    readonly restaurant: Restaurant
}

/** Rating contexts enum */
export enum RatingContextsEnum {
    /** Dish detail ratings */
    DishDetailRatings = 'DISH_DETAIL_RATINGS',
    /** None */
    None = 'None',
    /** Ordered dish ratings */
    OrderedDishRatings = 'ORDERED_DISH_RATINGS',
}

export interface RefreshTokenInput {
    readonly refresh_token?: InputMaybe<Scalars['String']['input']>
}

export interface RefreshTokenPayload {
    readonly access_token: Scalars['String']['output']
    readonly expires_in: Scalars['Int']['output']
    readonly refresh_token: Scalars['String']['output']
    readonly token_type: Scalars['String']['output']
}

export interface RegisterInput {
    readonly email: Scalars['String']['input']
    readonly firstName: Scalars['String']['input']
    readonly lastName: Scalars['String']['input']
    readonly password: Scalars['String']['input']
    readonly password_confirmation: Scalars['String']['input']
    readonly preferredLanguage?: InputMaybe<Scalars['String']['input']>
}

export interface RegisterResponse {
    readonly status: RegisterStatuses
    readonly tokens?: Maybe<AuthPayload>
}

export enum RegisterStatuses {
    MustVerifyEmail = 'MUST_VERIFY_EMAIL',
    Success = 'SUCCESS',
}

/**
 * The url used to reset the password.
 * Use the `__EMAIL__` and `__TOKEN__` placeholders to inject the reset password email and token.
 *
 * e.g; `https://my-front-end.com?reset-password?email=__EMAIL__&token=__TOKEN__`
 */
export interface ResetPasswordUrlInput {
    readonly url: Scalars['String']['input']
}

export interface Restaurant {
    readonly allowPickUpAtTheOffice: Scalars['Boolean']['output']
    readonly ambianceImage: AmbianceImages
    readonly categories: ReadonlyArray<ProductCategory>
    readonly clients: ReadonlyArray<Client>
    readonly createdAt: Scalars['DateTime']['output']
    readonly dishBackgroundImage?: Maybe<DishBackgroundImages>
    readonly hasTimeslots: Scalars['Boolean']['output']
    readonly id: Scalars['ID']['output']
    readonly isQrCodePickup: Scalars['Boolean']['output']
    readonly location?: Maybe<Location>
    readonly name: Scalars['String']['output']
    readonly officeDays: ReadonlyArray<OfficeDay>
    readonly orderDeadlineMarginMinutes: Scalars['Int']['output']
    readonly orderStrategies: ReadonlyArray<RestaurantOrderStrategy>
    readonly pickupInstructions?: Maybe<Scalars['String']['output']>
    readonly serviceReferenceID?: Maybe<Scalars['String']['output']>
    readonly type: RestaurantTypeEnum
    readonly updatedAt: Scalars['DateTime']['output']
}

/** Restaurant ambiance image variant enum */
export enum RestaurantAmbianceImageVariantEnum {
    /** Original */
    Original = 'ORIGINAL',
    /** Rectangle 615 */
    Rectangle_615 = 'RECTANGLE_615',
}

export interface RestaurantBelongsTo {
    readonly connect: Scalars['ID']['input']
}

export interface RestaurantClientAssociationInput {
    readonly clientId: Scalars['ID']['input']
    readonly orderStrategy: OrderStrategyEnum
    readonly orderStrategyNote?: InputMaybe<Scalars['String']['input']>
}

export interface RestaurantOpeningHours {
    readonly friday?: Maybe<OpeningHours>
    readonly monday?: Maybe<OpeningHours>
    readonly saturday?: Maybe<OpeningHours>
    readonly sunday?: Maybe<OpeningHours>
    readonly thursday?: Maybe<OpeningHours>
    readonly tuesday?: Maybe<OpeningHours>
    readonly wednesday?: Maybe<OpeningHours>
}

export interface RestaurantOpeningHoursInput {
    readonly closesAt: Scalars['String']['input']
    readonly opensAt: Scalars['String']['input']
    readonly weekday: WeekDayEnum
}

export interface RestaurantOrderStrategy {
    readonly clientID: Scalars['ID']['output']
    readonly note?: Maybe<Scalars['String']['output']>
    readonly restaurantID: Scalars['ID']['output']
    readonly value: OrderStrategyEnum
}

export interface RestaurantOrderStrategyWithModels {
    readonly client: Client
    readonly note?: Maybe<Scalars['String']['output']>
    readonly restaurant: Restaurant
    readonly value: OrderStrategyEnum
}

export interface RestaurantTimeslotConfiguration {
    readonly amountInOneDay?: Maybe<Scalars['Int']['output']>
    readonly capacity?: Maybe<Scalars['Int']['output']>
    readonly durationInMinutes?: Maybe<Scalars['Int']['output']>
    readonly hasTimeslots: Scalars['Boolean']['output']
    readonly startingTime?: Maybe<Scalars['String']['output']>
}

export interface RestaurantTimeslotSettingsInput {
    readonly amountInOneDay: Scalars['Int']['input']
    readonly capacity: Scalars['Int']['input']
    readonly durationInMinutes: Scalars['Int']['input']
    readonly startingTime: Scalars['String']['input']
}

/** Restaurant type enum */
export enum RestaurantTypeEnum {
    /** Delivery */
    Delivery = 'DELIVERY',
    /** Order on demand */
    OrderOnDemand = 'ORDER_ON_DEMAND',
    /** Restaurant */
    Restaurant = 'RESTAURANT',
}

/** Lists all the associations and their auxiliary pivot data of Client with a particular Restaurant. */
export interface RestaurantWithClientAssociations {
    readonly associations: ReadonlyArray<ClientRestaurantAssociation>
    readonly restaurant: Restaurant
}

export interface RestaurantWithProductCategories {
    readonly productCategories: ReadonlyArray<ProductCategory>
    readonly restaurant: Restaurant
}

export interface RestaurantsFilterInput {
    readonly clients?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly locations?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly query?: InputMaybe<Scalars['String']['input']>
}

export interface RestaurantsInput {
    readonly filters?: InputMaybe<RestaurantsFilterInput>
    readonly first: Scalars['Int']['input']
    readonly page?: InputMaybe<Scalars['Int']['input']>
}

export interface RestaurantsPaginator {
    readonly data: ReadonlyArray<Restaurant>
    readonly paginatorInfo: PaginatorInfo
}

export interface Role {
    readonly createdAt: Scalars['DateTime']['output']
    readonly id: Scalars['ID']['output']
    readonly name: RoleNameEnum
    readonly updatedAt: Scalars['DateTime']['output']
}

export interface RoleInput {
    readonly name: RoleNameEnum
}

/** Role name enum */
export enum RoleNameEnum {
    /** Admin */
    Admin = 'ADMIN',
    /** Content creator */
    ContentCreator = 'CONTENT_CREATOR',
    /** Curator */
    Curator = 'CURATOR',
    /** Employee */
    Employee = 'EMPLOYEE',
    /** Finance manager */
    FinanceManager = 'FINANCE_MANAGER',
    /** Location manager */
    LocationManager = 'LOCATION_MANAGER',
    /** Regional manager */
    RegionalManager = 'REGIONAL_MANAGER',
    /** Staff on location */
    StaffOnLocation = 'STAFF_ON_LOCATION',
    /** Web application */
    WebApplication = 'WEB_APPLICATION',
}

export interface SavePurchaseOrderInput {
    readonly isoWeekNumber: Scalars['IsoWeekNumber']['input']
    readonly isoWeekYear: Scalars['IsoWeekYear']['input']
    readonly purchaseOrderLines: ReadonlyArray<SavePurchaseOrderLineInput>
    readonly restaurantId: Scalars['ID']['input']
    readonly submitted?: InputMaybe<Scalars['Boolean']['input']>
}

export interface SavePurchaseOrderLineInput {
    readonly isoWeekday: Scalars['IsoDayNumber']['input']
    readonly productCategoryId: Scalars['ID']['input']
    readonly quantity: Scalars['Int']['input']
}

export interface SendResetPasswordEmailInput {
    readonly userId: Scalars['ID']['input']
}

export interface ShortcodeRegisterInput {
    readonly email: Scalars['String']['input']
    readonly firstName: Scalars['String']['input']
    readonly lastName: Scalars['String']['input']
    readonly password: Scalars['String']['input']
    readonly password_confirmation: Scalars['String']['input']
    readonly preferredLanguage?: InputMaybe<Scalars['String']['input']>
    readonly registrationShortcode: Scalars['String']['input']
}

/** Directions for ordering a list of records. */
export enum SortOrder {
    /** Sort records in ascending order. */
    Asc = 'ASC',
    /** Sort records in descending order. */
    Desc = 'DESC',
}

/** Sorting direction enum */
export enum SortingDirectionEnum {
    /** Ascending */
    Ascending = 'ASCENDING',
    /** Descending */
    Descending = 'DESCENDING',
}

export interface SortingInput {
    readonly direction: SortingDirectionEnum
    readonly key: Scalars['String']['input']
}

/** Stock mutation type enum */
export enum StockMutationTypeEnum {
    /**
   *
   *     The CLAIM mutation increases a stock's amountClaimed by a given number, which will also increment its derived value
   *     amountReserved and decrement its derived value amountAvailable by the same number.
   *
   */
    Claim = 'CLAIM',
    /**
   *
   *     The DECREASE_TOTAL mutation decrements a stock's amount total correction by a given number,
   *     which will also decrement the following derived values by the same number:
   *     - amount total
   *     - amount available
   *     - amount issuable
   *
   */
    DecreaseTotal = 'DECREASE_TOTAL',
    /**
   *
   *     The INCREASE_TOTAL mutation increments a stock's amount total by a given number,
   *     which will also increment the following derived values by the same number:
   *     - amount total
   *     - amount available
   *     - amount issuable
   *
   */
    IncreaseTotal = 'INCREASE_TOTAL',
    /**
   *
   *     The ISSUE mutation increases a stock's amountIssued by a given number, which will also _decrement_ its derived values
   *     amountReserved and amountIssuable by the same number.
   *
   */
    Issue = 'ISSUE',
    /**
   *
   *     The ISSUE_AVAILABLE mutation increases a stock's amountIssued AND amountClaimed by a given number, which will also
   *     decrement its derived values amountAvailable and amountIssuable by the same number.
   *
   *     Note: This is the same as first claiming and then issuing stock by the given number, used by kitchen screens to issue
   *     freely available stock in one go.
   *
   */
    IssueAvailable = 'ISSUE_AVAILABLE',
    /**
   *
   *     The SET_TOTAL_INITIAL mutation sets a stock's `amountTotalInitial` to a given number.
   *     This mutation can only be used on currently unconstrained stocks, i.e. `amountTotalInitial === null`.
   *     The given value must be greater than or equal to the `amountClaimed`.
   *
   */
    SetTotalInitial = 'SET_TOTAL_INITIAL',
    /**
   *
   *     The UN_CLAIM mutation decrements a stock's amountClaimed by a given number, which will also decrement its derived
   *     value amountReserved and increment its derived value amountAvailable by the same number.
   *
   */
    UnClaim = 'UN_CLAIM',
    /**
   *
   *     The UN_CLAIM_ISSUED mutation decrements a stock's amountIssued AND amountClaimed by a given number, which will also
   *     increment its derived values amountAvailable and amountIssuable by the same number.
   *
   *     ---
   *
   *     Note: This is the same as first un-issuing and then un-claiming stock by the given number, which can be imagined as
   *     somebody who returns a previously issued dish, re-adding it to the available stock.
   *
   */
    UnClaimIssued = 'UN_CLAIM_ISSUED',
    /**
   *
   *     The UN_ISSUE mutation decreases a stock's amountIssued by a given number, which will also _increment_ its derived
   *     values amountReserved and amountIssuable by the same number.
   *
   */
    UnIssue = 'UN_ISSUE',
}

/**
 * A set of numbers that communicate the status of stock for a particular menu-item. This object
 * consists of 4 canonical values and 4 derived values. Additionally has some boolean flags that
 * provide assertion shortcuts for the contained numbers.
 */
export interface StockNumbers {
    /**
   * The amount of stock that is available for new orders and free issuing by kitchen staff.
   * This value will be null when no total constraint is actively enforced.
   */
    readonly amountAvailable?: Maybe<Scalars['Int']['output']>
    /**
   * (canonical) The amount of stock that has been claimed, i.e. (pre-)ordered. An item can be
   * claimed either by consumers through pre-orders in the app, or by kitchen staff who issue
   * free stock to ad-hoc consumers.
   */
    readonly amountClaimed: Scalars['Int']['output']
    /**
   * The portion of the amount total that has not yet been issued.
   * This value will be null when no total constraint is actively enforced.
   */
    readonly amountIssuable?: Maybe<Scalars['Int']['output']>
    /**
   * (canonical) The amount of stock that has been issued to consumers. All items that are issued must have
   * been claimed before, i.e. for each item issued, the amount claimed must have been incremented
   * by 1 previously, or at the time of issuing.
   */
    readonly amountIssued: Scalars['Int']['output']
    /** The amount of stock that has been claimed but not yet issued. */
    readonly amountReserved: Scalars['Int']['output']
    /**
   * This is the corrected total amount of stock that is available for an item.
   * This value will be null when no total constraint is actively enforced.
   */
    readonly amountTotal?: Maybe<Scalars['Int']['output']>
    /**
   * (canonical) The manual correction on the amountTotalInitial. This is the only number that
   * can be less than zero, implying a lower available stock than the (immutable) amountTotalInitial
   * implies. It can never be less than the negative mirror of amountTotalInitial,
   * e.g. if amountTotalInitial = 100, amountTotalCorrection >= -100.
   */
    readonly amountTotalCorrection: Scalars['Int']['output']
    /**
   * (canonical) This is the initial total amount of stock that is available for an item.
   * When his value is null it means that no total constraint is actively enforced.
   */
    readonly amountTotalInitial?: Maybe<Scalars['Int']['output']>
    /**
   * If true, the stock is actively constrained to a maximum and all nullable numbers in
   * this object will have an integer value. If false, all nullable numbers will be NULL.
   * Shortcut for `amountTotalInitial !== null`.
   */
    readonly isConstrained: Scalars['Boolean']['output']
    /**
   * Indicates if the total stock has been adjusted manually by kitchen staff, and therefore
   * the stockTotalInitial should be considered outdated. Adjustments can only have been made
   * on constrained stocks.
   * Shortcut for `amountTotalInitial !== null && stockTotalCorrection !== 0`.
   */
    readonly isCorrected: Scalars['Boolean']['output']
    /**
   * Indicates if the stock is fully used.
   * Shortcut for `amountTotalInitial !== null && amountAvailable === 0`.
   */
    readonly isExhausted: Scalars['Boolean']['output']
}

export interface StorableClientTheme {
    readonly colorError?: Maybe<Scalars['String']['output']>
    readonly colorErrorContrast?: Maybe<Scalars['String']['output']>
    readonly colorPrimary: Scalars['String']['output']
    readonly colorPrimaryContrast?: Maybe<Scalars['String']['output']>
    readonly colorSecondary: Scalars['String']['output']
    readonly colorSecondaryContrast?: Maybe<Scalars['String']['output']>
    readonly colorSuccess?: Maybe<Scalars['String']['output']>
    readonly colorSuccessContrast?: Maybe<Scalars['String']['output']>
    readonly colorTertiary: Scalars['String']['output']
    readonly colorTertiaryContrast?: Maybe<Scalars['String']['output']>
    readonly colorTextDark: Scalars['String']['output']
    readonly colorTextLight: Scalars['String']['output']
    readonly colorWarning?: Maybe<Scalars['String']['output']>
    readonly colorWarningContrast?: Maybe<Scalars['String']['output']>
    readonly contextualColors?: Maybe<ReadonlyArray<ContextualColor>>
    readonly isActiveForApp: Scalars['Boolean']['output']
    readonly isActiveForWeb: Scalars['Boolean']['output']
}

export interface Tag {
    readonly id: Scalars['ID']['output']
    readonly name: Scalars['String']['output']
    readonly products: ReadonlyArray<Product>
}

/** Tag strategy enum */
export enum TagStrategyEnum {
    /** Merge */
    Merge = 'MERGE',
    /** Remove */
    Remove = 'REMOVE',
    /** Replace */
    Replace = 'REPLACE',
}

export interface TemplateMenu {
    readonly activeFrom?: Maybe<Scalars['DateTime']['output']>
    readonly activeUntil?: Maybe<Scalars['DateTime']['output']>
    readonly entries: ReadonlyArray<TemplateMenuEntry>
    readonly id: Scalars['ID']['output']
    readonly restaurant: Restaurant
    readonly restaurantId: Scalars['ID']['output']
}

export interface TemplateMenuEntry {
    readonly defaultStock?: Maybe<Scalars['Int']['output']>
    readonly id: Scalars['ID']['output']
    readonly position: Scalars['Int']['output']
    readonly prices: ReadonlyArray<TemplateMenuEntryPrice>
    readonly product: Product
    readonly recurrence: ReadonlyArray<Scalars['IsoDayNumber']['output']>
    readonly templateMenu: TemplateMenu
    readonly templateMenuId: Scalars['ID']['output']
}

export interface TemplateMenuEntryCreationInput {
    /**
   * Optionally provide the default stock to write to future menus.
   * When not set, the stock will be considered unlimited.
   */
    readonly defaultStock?: InputMaybe<Scalars['Int']['input']>
    readonly productId: Scalars['ID']['input']
    /**
   * Provide a list of ISO day-numbers for the days on which this entry should recur
   * on future menus. Empty lists may be passed for provisional entries whose recurrences
   * will be provided later on.
   */
    readonly recurrence: ReadonlyArray<Scalars['IsoDayNumber']['input']>
    readonly restaurantId: Scalars['ID']['input']
}

export interface TemplateMenuEntryPositionInput {
    readonly position: Scalars['Int']['input']
    readonly templateMenuEntryId: Scalars['ID']['input']
}

export interface TemplateMenuEntryPrice {
    readonly client: Client
    readonly clientId: Scalars['ID']['output']
    readonly entry: TemplateMenuEntry
    readonly id: Scalars['ID']['output']
    readonly sellingPrice: Scalars['Cent']['output']
    readonly templateMenuEntryId: Scalars['ID']['output']
}

/**
 * Input for a client-specific price for a containing template-menu-entry.
 * When the sellingPrice property is null any existing record matching
 * the client and containing template-menu-entry will be removed from storage.
 */
export interface TemplateMenuEntryPriceInput {
    readonly clientId: Scalars['ID']['input']
    readonly sellingPrice?: InputMaybe<Scalars['Cent']['input']>
}

export interface TemplateMenuEntryUpdateInput {
    /**
   * Optionally provide the daily stock to write to future menus.
   * When not set, the stock will be considered unlimited.
   * If the currently saved value should remain unchanged, pass the current value in this field.
   */
    readonly defaultStock?: InputMaybe<Scalars['Int']['input']>
    readonly id: Scalars['ID']['input']
    /**
   * Provide the prices for each client served by the restaurant that this entry is for. While
   * a price is omitted for a particular client, users of that client will not see the item in
   * their app feed.
   */
    readonly prices: ReadonlyArray<TemplateMenuEntryPriceInput>
    /**
   * Provide a list of ISO day-numbers for the days on which this entry should recur on future menus.
   * Empty lists may be passed for provisional entries whose recurrences will be provided later on.
   * If the currently saved value should remain unchanged, pass it in this field.
   */
    readonly recurrence: ReadonlyArray<Scalars['IsoDayNumber']['input']>
}

export interface Timeslot {
    readonly capacityClaimed: Scalars['String']['output']
    readonly capacityTotal: Scalars['String']['output']
    readonly endsAt: Scalars['String']['output']
    readonly id: Scalars['ID']['output']
    readonly restaurant: Restaurant
    readonly startsAt: Scalars['String']['output']
}

export interface TimeslotBelongsTo {
    readonly connect: Scalars['ID']['input']
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
    /** Only return trashed results. */
    Only = 'ONLY',
    /** Return both trashed and non-trashed results. */
    With = 'WITH',
    /** Only return non-trashed results. */
    Without = 'WITHOUT',
}

export interface TwoFactorAuthenticationConfirmInput {
    readonly code: Scalars['String']['input']
    readonly password?: InputMaybe<Scalars['String']['input']>
    readonly username?: InputMaybe<Scalars['String']['input']>
}

export interface TwoFactorAuthenticationEnableInput {
    readonly password?: InputMaybe<Scalars['String']['input']>
    readonly username?: InputMaybe<Scalars['String']['input']>
}

export interface TwoFactorSecret {
    readonly decrypted: Scalars['String']['output']
    readonly qr: Scalars['String']['output']
}

export interface UpdateAllergenInput {
    readonly icon?: InputMaybe<DeletableFileInput>
    readonly id: Scalars['ID']['input']
    readonly name?: InputMaybe<Scalars['String']['input']>
}

export interface UpdateClientInput {
    readonly emailQuestionsTo: Scalars['String']['input']
    readonly id: Scalars['ID']['input']
    readonly logo?: InputMaybe<DeletableFileInput>
    readonly name: Scalars['String']['input']
    readonly onboardingSteps: ReadonlyArray<OnboardingStepsEnum>
    readonly ratingContexts: ReadonlyArray<RatingContextsEnum>
}

export interface UpdateClientThemeInput {
    readonly colorError?: InputMaybe<Scalars['String']['input']>
    readonly colorErrorContrast?: InputMaybe<Scalars['String']['input']>
    readonly colorPrimary: Scalars['String']['input']
    readonly colorPrimaryContrast?: InputMaybe<Scalars['String']['input']>
    readonly colorSecondary: Scalars['String']['input']
    readonly colorSecondaryContrast?: InputMaybe<Scalars['String']['input']>
    readonly colorSuccess?: InputMaybe<Scalars['String']['input']>
    readonly colorSuccessContrast?: InputMaybe<Scalars['String']['input']>
    readonly colorTertiary: Scalars['String']['input']
    readonly colorTertiaryContrast?: InputMaybe<Scalars['String']['input']>
    readonly colorTextDark: Scalars['String']['input']
    readonly colorTextLight: Scalars['String']['input']
    readonly colorWarning?: InputMaybe<Scalars['String']['input']>
    readonly colorWarningContrast?: InputMaybe<Scalars['String']['input']>
    readonly contextualColors: ReadonlyArray<ContextualColorInput>
    readonly isActiveForApp: Scalars['Boolean']['input']
    readonly isActiveForWeb: Scalars['Boolean']['input']
}

export interface UpdateContentMediaResponse {
    readonly contentMedia?: Maybe<ContentMedia>
    readonly deleted: Scalars['Boolean']['output']
}

export interface UpdateInternalVoucherInput {
    readonly activeFrom: Scalars['DateTime']['input']
    readonly activeUntil: Scalars['DateTime']['input']
    readonly id: Scalars['ID']['input']
    readonly initialAmount: Scalars['Cent']['input']
    readonly userId: Scalars['ID']['input']
}

export interface UpdateLocationInput {
    readonly cityName: Scalars['String']['input']
    readonly costCenter?: InputMaybe<Scalars['String']['input']>
    readonly locationId: Scalars['ID']['input']
    readonly name: Scalars['String']['input']
    readonly postalCode: Scalars['String']['input']
    readonly streetName: Scalars['String']['input']
    readonly streetNumber: Scalars['String']['input']
}

export interface UpdateMeInput {
    readonly avatar?: InputMaybe<DeletableFileInput>
    readonly cityName?: InputMaybe<Scalars['String']['input']>
    readonly email?: InputMaybe<Scalars['String']['input']>
    readonly firstName?: InputMaybe<Scalars['String']['input']>
    readonly lastName?: InputMaybe<Scalars['String']['input']>
    readonly location?: InputMaybe<Scalars['ID']['input']>
    readonly newsReadAt?: InputMaybe<Scalars['DateTime']['input']>
    readonly postalCode?: InputMaybe<Scalars['String']['input']>
    readonly preferredLanguage?: InputMaybe<Scalars['String']['input']>
    readonly privacyPolicyAcceptedAt?: InputMaybe<Scalars['DateTime']['input']>
    readonly streetName?: InputMaybe<Scalars['String']['input']>
    readonly streetNumber?: InputMaybe<Scalars['String']['input']>
    readonly termsAcceptedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export interface UpdatePassword {
    readonly old_password: Scalars['String']['input']
    readonly password: Scalars['String']['input']
    readonly password_confirmation: Scalars['String']['input']
}

export interface UpdatePasswordResponse {
    readonly message?: Maybe<Scalars['String']['output']>
    readonly status: Scalars['String']['output']
}

export interface UpdatePollInput {
    readonly clients: ReadonlyArray<QuestionClientInput>
    readonly endDate: Scalars['Date']['input']
    readonly id: Scalars['ID']['input']
    readonly startDate: Scalars['Date']['input']
    readonly translations: ReadonlyArray<QuestionTranslationInput>
    readonly type: QuestionTypeEnum
}

export interface UpdateProductCategoryInput {
    readonly id: Scalars['ID']['input']
    readonly name: Scalars['String']['input']
    readonly publicCategory: Scalars['ID']['input']
}

export interface UpdateProductInput {
    readonly additions?: InputMaybe<ReadonlyArray<CreateAdditionInput>>
    readonly allergens?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly category: Scalars['ID']['input']
    readonly description?: InputMaybe<Scalars['String']['input']>
    readonly dietaryClass?: InputMaybe<Scalars['String']['input']>
    readonly id: Scalars['ID']['input']
    readonly image?: InputMaybe<DeletableFileInput>
    readonly marginPercentage: Scalars['Int']['input']
    readonly name: Scalars['String']['input']
    readonly nutrients?: InputMaybe<ReadonlyArray<CreateNutrientForProductVersionInput>>
    readonly price: Scalars['Cent']['input']
    readonly shortDescription?: InputMaybe<Scalars['String']['input']>
    readonly tagline?: InputMaybe<Scalars['String']['input']>
    readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>
    readonly type: ProductTypeEnum
}

export interface UpdatePublicCategoryInput {
    readonly id: Scalars['ID']['input']
    readonly name: Scalars['String']['input']
}

export interface UpdateRestaurantInput {
    readonly allowPickUpAtTheOffice: Scalars['Boolean']['input']
    readonly ambianceImage?: InputMaybe<DeletableFileInput>
    readonly dishBackgroundImage?: InputMaybe<DeletableFileInput>
    readonly id: Scalars['ID']['input']
    readonly locationId?: InputMaybe<Scalars['ID']['input']>
    readonly name: Scalars['String']['input']
    readonly orderDeadlineMarginMinutes: Scalars['Int']['input']
    readonly pickupInstructions?: InputMaybe<Scalars['String']['input']>
    readonly qrCodePickup: Scalars['Boolean']['input']
    readonly serviceReferenceId?: InputMaybe<Scalars['String']['input']>
    readonly type: RestaurantTypeEnum
}

export interface UpdateStockResponse {
    readonly menuEntryOccurrenceID: Scalars['ID']['output']
    readonly mutationQuantity: Scalars['Int']['output']
    readonly mutationType: StockMutationTypeEnum
    readonly nextNumbers: StockNumbers
    readonly prevNumbers: StockNumbers
}

export interface UpdateUserAllergensInput {
    readonly allergens?: InputMaybe<ReadonlyArray<AllergensBelongsTo>>
}

export interface UpdateUserInput {
    readonly clientID: Scalars['ID']['input']
    readonly email?: InputMaybe<Scalars['String']['input']>
    readonly firstName?: InputMaybe<Scalars['String']['input']>
    readonly lastName?: InputMaybe<Scalars['String']['input']>
    readonly location?: InputMaybe<Scalars['ID']['input']>
    readonly preferredLanguage?: InputMaybe<Scalars['String']['input']>
    readonly roles?: InputMaybe<ReadonlyArray<RoleInput>>
    readonly status?: InputMaybe<UserStatusEnum>
    readonly userId: Scalars['ID']['input']
}

export interface User {
    readonly allergens: ReadonlyArray<Allergen>
    readonly avatar?: Maybe<Scalars['String']['output']>
    readonly cityName?: Maybe<Scalars['String']['output']>
    readonly client: Client
    readonly createdAt: Scalars['DateTime']['output']
    readonly deletedAt?: Maybe<Scalars['DateTime']['output']>
    readonly email: Scalars['String']['output']
    readonly firstName: Scalars['String']['output']
    readonly hasConfirmedTwoFactor: Scalars['Boolean']['output']
    readonly id: Scalars['ID']['output']
    readonly lastLogin?: Maybe<Scalars['DateTime']['output']>
    readonly lastName: Scalars['String']['output']
    readonly location?: Maybe<Location>
    readonly newsReadAt?: Maybe<Scalars['DateTime']['output']>
    readonly officeDays: ReadonlyArray<OfficeDay>
    readonly postalCode?: Maybe<Scalars['String']['output']>
    readonly preferredLanguage: Scalars['String']['output']
    readonly privacyPolicyAcceptedAt?: Maybe<Scalars['DateTime']['output']>
    readonly requiresPrivacyPolicyAcceptance: Scalars['Boolean']['output']
    readonly requiresTermsAcceptance: Scalars['Boolean']['output']
    readonly roles: ReadonlyArray<Role>
    readonly status: UserStatusEnum
    readonly streetName?: Maybe<Scalars['String']['output']>
    readonly streetNumber?: Maybe<Scalars['String']['output']>
    readonly termsAcceptedAt?: Maybe<Scalars['DateTime']['output']>
    readonly updatedAt: Scalars['DateTime']['output']
}


export interface UserAvatarArgs {
    variant?: InputMaybe<UserAvatarVariant>
}

export interface UserAllergen {
    readonly allergen: Allergen
    readonly createdAt: Scalars['DateTime']['output']
    readonly id: Scalars['ID']['output']
    readonly updatedAt: Scalars['DateTime']['output']
    readonly user: User
}

/** Allowed resizes for the `variant` argument on the query `avatar`. */
export enum UserAvatarVariant {
    Original = 'ORIGINAL',
    Small = 'SMALL',
}

export interface UserFilter {
    readonly clients?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
    readonly lastLogin?: InputMaybe<Scalars['Date']['input']>
    readonly query?: InputMaybe<Scalars['String']['input']>
    readonly roles?: InputMaybe<ReadonlyArray<RoleNameEnum>>
    readonly statuses?: InputMaybe<ReadonlyArray<UserStatusEnum>>
}

export interface UserStatus {
    readonly key: UserStatusEnum
    readonly value: Scalars['String']['output']
}

/** User status enum */
export enum UserStatusEnum {
    /** Active */
    Active = 'ACTIVE',
    /** Blocked */
    Blocked = 'BLOCKED',
    /** Must verify email */
    MustVerifyEmail = 'MUST_VERIFY_EMAIL',
}

export interface UsersFilterInput {
    readonly filters?: InputMaybe<UserFilter>
    readonly first: Scalars['Int']['input']
    readonly page?: InputMaybe<Scalars['Int']['input']>
}

export interface UsersPaginator {
    readonly data: ReadonlyArray<User>
    readonly paginatorInfo: PaginatorInfo
}

export interface VerifyEmailInput {
    readonly token: Scalars['String']['input']
}

export interface Voucher {
    readonly email: Scalars['String']['output']
    readonly endDate: Scalars['String']['output']
    readonly id: Scalars['Int']['output']
    readonly provider: IntegrationProviderEnum
    readonly startDate: Scalars['String']['output']
    /** @deprecated This has the same value as id. Use id instead. */
    readonly sysCode: Scalars['Int']['output']
    readonly voucherCode: Scalars['String']['output']
    readonly voucherOpenValue: Scalars['Int']['output']
    readonly voucherUsedValue: Scalars['Int']['output']
    readonly voucherValue: Scalars['Int']['output']
}

export interface VoucherUsageExportInput {
    readonly clientId: Scalars['ID']['input']
    readonly endDate: Scalars['Date']['input']
    readonly startDate: Scalars['Date']['input']
}

export interface VoucherUsageInput {
    readonly code: Scalars['String']['input']
    readonly provider: IntegrationProviderEnum
}

/** Week day enum */
export enum WeekDayEnum {
    /** Friday */
    Friday = 'FRIDAY',
    /** Monday */
    Monday = 'MONDAY',
    /** Saturday */
    Saturday = 'SATURDAY',
    /** Sunday */
    Sunday = 'SUNDAY',
    /** Thursday */
    Thursday = 'THURSDAY',
    /** Tuesday */
    Tuesday = 'TUESDAY',
    /** Wednesday */
    Wednesday = 'WEDNESDAY',
}

export interface TimeslotsInput {
    readonly date: Scalars['Date']['input']
    readonly restaurant: RestaurantBelongsTo
}

export type AllergensQueryVariables = Exact<{ [key: string]: never }>


export type AllergensQuery = { readonly allergens: ReadonlyArray<AllergenFragment> }

export type LatestAppVersionsQueryVariables = Exact<{ [key: string]: never }>


export type LatestAppVersionsQuery = { readonly latestAppVersions: LatestAppVersionsFragment }

export type LatestAppVersionsFragment = { readonly android?: string | null; readonly ios?: string | null }

export type LogoutMutationVariables = Exact<{ [key: string]: never }>


export type LogoutMutation = { readonly logout: { readonly message?: string | null; readonly status: string } }

export type RefreshTokenMutationVariables = Exact<{
    input?: InputMaybe<RefreshTokenInput>
}>


export type RefreshTokenMutation = { readonly refreshToken: RefreshResponseFragment }

export type ForgotPasswordMutationVariables = Exact<{
    email: Scalars['String']['input']
}>


export type ForgotPasswordMutation = { readonly forgotPassword: string }

export type UpdateForgottenPasswordMutationVariables = Exact<{
    input: NewPasswordWithCodeInput
}>


export type UpdateForgottenPasswordMutation = { readonly updateForgottenPassword: { readonly message?: string | null; readonly status: string } }

export type MeQueryVariables = Exact<{ [key: string]: never }>


export type MeQuery = { readonly me: UserFragment }

export type WeekDayEnumsQueryVariables = Exact<{ [key: string]: never }>


export type WeekDayEnumsQuery = { readonly weekDayEnums: ReadonlyArray<WeekDayEnum> }

export type ClientSuggestionQueryVariables = Exact<{
    email: Scalars['String']['input']
}>


export type ClientSuggestionQuery = { readonly clientSuggestion?: ClientSuggestionFragment | null }

export type UpdateMeMutationVariables = Exact<{
    input: UpdateMeInput
}>


export type UpdateMeMutation = { readonly updateMe: { readonly id: string } }

export type UpdateUserAllergensMutationVariables = Exact<{
    input: UpdateUserAllergensInput
}>


export type UpdateUserAllergensMutation = { readonly updateUserAllergens: { readonly allergens: ReadonlyArray<{ readonly name: string }> } }

export type DeleteMeMutationVariables = Exact<{ [key: string]: never }>


export type DeleteMeMutation = { readonly deleteMe: boolean }

export type UpdateUserOfficeDaysMutationVariables = Exact<{
    input: ChangeOfficeDaysInput
}>


export type UpdateUserOfficeDaysMutation = { readonly updateOfficeDays: ReadonlyArray<{ readonly id: string; readonly dayOfTheWeek: WeekDayEnum }> }

export type RegisterMutationVariables = Exact<{
    input: RegisterInput
}>


export type RegisterMutation = { readonly register: RegisterResponseFragment }

export type ShortcodeRegisterMutationVariables = Exact<{
    input: ShortcodeRegisterInput
}>


export type ShortcodeRegisterMutation = { readonly shortcodeRegister: RegisterResponseFragment }

export type EnableTwoFactorMutationVariables = Exact<{ [key: string]: never }>


export type EnableTwoFactorMutation = { readonly twoFactorAuthenticationEnable: TwoFactorSecretFragment }

export type DisableTwoFactorMutationVariables = Exact<{ [key: string]: never }>


export type DisableTwoFactorMutation = { readonly twoFactorAuthenticationDisable?: boolean | null }

export type TwoFactorSecretFragment = { readonly qr: string; readonly decrypted: string }

export type ClientSuggestionFragment = { readonly id: string; readonly registrationShortcode: string; readonly name: string; readonly emailQuestionsTo: string }

export type ConfirmTwoFactorCodeMutationVariables = Exact<{
    code: Scalars['String']['input']
}>


export type ConfirmTwoFactorCodeMutation = { readonly twoFactorAuthenticationConfirm: ReadonlyArray<string> }

export type LocalLoginMutationVariables = Exact<{
    clientId: Scalars['ID']['input']
    clientSecret: Scalars['String']['input']
    password: Scalars['String']['input']
    username: Scalars['String']['input']
}>


export type LocalLoginMutation = { readonly localLogin: LoginResponseFragment }

export type StartPkceLoginMutationVariables = Exact<{
    emailAddress?: InputMaybe<Scalars['String']['input']>
    shortcode?: InputMaybe<Scalars['String']['input']>
    codeChallenge: Scalars['String']['input']
    fallbackRedirectUri: Scalars['String']['input']
    redirectUri: Scalars['String']['input']
}>


export type StartPkceLoginMutation = { readonly startPkceLogin: StartPkceLoginResponseFragment }

export type StartPkceLoginResponseFragment = { readonly redirectUri: string; readonly isFallbackRedirectUri: boolean; readonly clientResolutionStrategy: ClientResolutionStrategyEnum }

export type FinishPkceLoginMutationVariables = Exact<{
    codeVerifier: Scalars['String']['input']
    exchangeToken: Scalars['String']['input']
    policiesAccepted: Scalars['Boolean']['input']
}>


export type FinishPkceLoginMutation = { readonly finishPkceLogin: AuthPayloadFragment }

export type AppCompatibilityStatusFragment = { readonly appVersion: string; readonly apiVersion: string; readonly compatible: boolean; readonly appSupportsApi: boolean; readonly apiSupportsApp: boolean }

export type CompatibilityQueryVariables = Exact<{
    appVersion: Scalars['Semver']['input']
    supportedApiRange: Scalars['SemverRange']['input']
}>


export type CompatibilityQuery = { readonly appCompatibility: AppCompatibilityStatusFragment }

export type DailyMenuQueryVariables = Exact<{
    clientID: Scalars['ID']['input']
    date: Scalars['Date']['input']
    filters?: InputMaybe<DailyMenuFilters>
}>


export type DailyMenuQuery = { readonly dailyMenu?: DailyMenuFragment | null }

export type DailyMenuItemQueryVariables = Exact<{
    menuEntryOccurrenceID: Scalars['ID']['input']
}>


export type DailyMenuItemQuery = { readonly dailyMenuItem: DailyMenuItemFragment }

export type DailyMenuFragment = { readonly date: string; readonly restaurants: ReadonlyArray<DailyMenuRestaurantFragment> }

export type DailyMenuRestaurantFragment = { readonly restaurant: RestaurantFragment; readonly items: ReadonlyArray<DailyMenuItemFragment> }

export type DailyMenuItemFragment = { readonly type: ProductTypeEnum; readonly name: string; readonly tagline?: string | null; readonly description?: string | null; readonly price: number; readonly dietaryClass?: string | null; readonly sources: DailyMenuItemSourceIDsFragment; readonly context: DailyMenuItemContextFragment; readonly tags: ReadonlyArray<TagFragment>; readonly category: PublicCategoryFragment; readonly allergens: ReadonlyArray<AllergenFragment>; readonly nutrients: ReadonlyArray<NutrientFragment>; readonly additions: ReadonlyArray<AdditionFragment>; readonly images?: DailyMenuItemImagesFragment | null; readonly stock: ApiStockNumbersFragment }

export type TagFragment = { readonly id: string; readonly name: string }

export type ApiStockNumbersFragment = { readonly amountTotal?: number | null; readonly amountAvailable?: number | null; readonly isConstrained: boolean }

export type DailyMenuItemSourceIDsFragment = { readonly menu: string; readonly menuEntry: string; readonly menuEntryOccurrence: string; readonly product: string; readonly productVersion: string }

export type DailyMenuItemContextFragment = { readonly date: string; readonly restaurantID: string }

export type DailyMenuItemImagesFragment = { readonly original: string; readonly square600: string; readonly square300: string; readonly square150: string }

export type AdditionFragment = { readonly id: string; readonly name: string; readonly price: number }

export type AllergenFragment = { readonly id: string; readonly name: string; readonly icon?: string | null }

export type NutrientFragment = { readonly perHundredGram?: number | null; readonly NutrientType?: { readonly unit: NutrientTypeUnitEnum; readonly name: string; readonly id: string; readonly isMacro: boolean } | null }

export type FeedFiltersQueryVariables = Exact<{
    locationID: Scalars['ID']['input']
    clientID: Scalars['ID']['input']
}>


export type FeedFiltersQuery = { readonly restaurants: { readonly data: ReadonlyArray<RestaurantFragment> }; readonly publicCategories: { readonly data: ReadonlyArray<PublicCategoryFragment> }; readonly dietaryClasses: ReadonlyArray<DietaryClassFragment> }

export type PublicCategoryFragment = { readonly __typename: 'PublicCategory'; readonly id: string; readonly name: string }

export type DietaryClassFragment = { readonly key: string; readonly value: string }

export type LoginResponseFragment = { readonly access_token?: string | null; readonly expires_in?: number | null; readonly refresh_token?: string | null; readonly token_type?: string | null }

export type RegisterResponseFragment = { readonly status: RegisterStatuses; readonly tokens?: LoginResponseFragment | null }

export type RefreshResponseFragment = { readonly access_token: string; readonly expires_in: number; readonly refresh_token: string; readonly token_type: string }

export type PaginatorFragment = { readonly count: number; readonly currentPage: number; readonly firstItem?: number | null; readonly hasMorePages: boolean; readonly lastItem?: number | null; readonly lastPage: number; readonly perPage: number; readonly total: number }

export type AuthPayloadFragment = { readonly access_token?: string | null; readonly expires_in?: number | null; readonly refresh_token?: string | null; readonly token_type?: string | null }

export type UserFragment = { readonly id: string, readonly status: UserStatusEnum, readonly firstName: string, readonly lastName: string, readonly email: string, readonly cityName?: string | null, readonly postalCode?: string | null, readonly streetName?: string | null, readonly streetNumber?: string | null, readonly newsReadAt?: string | null, readonly requiresPrivacyPolicyAcceptance: boolean, readonly requiresTermsAcceptance: boolean, readonly hasConfirmedTwoFactor: boolean, readonly avatar?: string | null, readonly client: (
    { readonly emailQuestionsTo: string }
    & ClientFragment
), readonly officeDays: ReadonlyArray<{ readonly dayOfTheWeek: WeekDayEnum }>, readonly location?: LocationFragment | null, readonly roles: ReadonlyArray<{ readonly name: RoleNameEnum; readonly id: string }>, readonly allergens: ReadonlyArray<AllergenFragment> }

export type LocationFragment = { readonly id: string; readonly name: string; readonly cityName: string; readonly postalCode: string; readonly streetName: string; readonly streetNumber: string }

export type ClientThemeFragment = { readonly colorPrimary: string; readonly colorPrimaryContrast: string; readonly colorSecondary: string; readonly colorSecondaryContrast: string; readonly colorTertiary: string; readonly colorTertiaryContrast: string; readonly colorTextDark: string; readonly colorTextLight: string; readonly colorSuccess: string; readonly colorSuccessContrast: string; readonly colorWarning: string; readonly colorWarningContrast: string; readonly colorError: string; readonly colorErrorContrast: string; readonly contextualColors: ReadonlyArray<{ readonly name: string; readonly value: string }> }

export type ClientFragment = { readonly id: string; readonly registrationShortcode: string; readonly name: string; readonly emailQuestionsTo: string; readonly ratingContexts: ReadonlyArray<RatingContextsEnum>; readonly logo: string; readonly onboardingSteps: ReadonlyArray<OnboardingStepsEnum>; readonly themes: { readonly app: ClientThemeFragment } }

export type NewsPagesQueryVariables = Exact<{
    input: NewsPagesInput
}>


export type NewsPagesQuery = { readonly newsPages?: { readonly data: ReadonlyArray<NewsPageIndexFragment> } | null }

export type NewsPageQueryVariables = Exact<{
    id: Scalars['ID']['input']
}>


export type NewsPageQuery = { readonly newsPage: NewsPageFragment }

export type NewsPageIndexFragment = { readonly id: string; readonly intro: string; readonly published_at?: string | null; readonly title: string; readonly heroImage?: string | null; readonly tagline?: string | null }

export type NewsPageFragment = (
  { readonly articles: ReadonlyArray<{ readonly id: string; readonly title?: string | null; readonly body?: string | null; readonly image?: string | null; readonly position?: string | null }> }
  & NewsPageIndexFragment
)

export type OrderHistoryQueryVariables = Exact<{
    input: OrderHistoryInput
}>


export type OrderHistoryQuery = { readonly orderHistory: { readonly paginatorInfo: PaginatorFragment; readonly data: ReadonlyArray<OrderHistoryOrderFragment> } }

export type LatestOrderQueryVariables = Exact<{
    startDate?: InputMaybe<Scalars['Date']['input']>
}>


export type LatestOrderQuery = { readonly orderHistory: { readonly data: ReadonlyArray<BaseOrderFragment> } }

export type PickupOrdersQueryVariables = Exact<{
    restaurant: Scalars['ID']['input']
    startDate: Scalars['Date']['input']
    endDate: Scalars['Date']['input']
    page: Scalars['Int']['input']
}>


export type PickupOrdersQuery = { readonly orderHistory: { readonly data: ReadonlyArray<BaseOrderFragment>; readonly paginatorInfo: PaginatorFragment } }

export type RequestOrderReceiptQueryVariables = Exact<{
    id: Scalars['ID']['input']
}>


export type RequestOrderReceiptQuery = { readonly requestOrderReceipt: { readonly status: OrderReceiptStatusEnum } }

export type OrderDetailQueryVariables = Exact<{
    id: Scalars['ID']['input']
}>


export type OrderDetailQuery = { readonly order: OrderDetailOrderFragment }

export type CreateOrdersMutationVariables = Exact<{
    orders: ReadonlyArray<OrderCreationInput> | OrderCreationInput
}>


export type CreateOrdersMutation = { readonly createOrders: ReadonlyArray<OrderDetailOrderFragment> }

export type PickupOrderMutationVariables = Exact<{
    id: Scalars['ID']['input']
    selectedOrderLineIds: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']
}>


export type PickupOrderMutation = { readonly pickUpOrder: BaseOrderFragment }

export type CancelOrderMutationVariables = Exact<{
    id: Scalars['ID']['input']
}>


export type CancelOrderMutation = { readonly setOrderStatus: { readonly nextStatus: OrderStatusEnum; readonly orderID: string } }

export type BaseOrderFragment = { readonly id: string, readonly status: OrderStatusEnum, readonly totalPrice: number, readonly paidAt?: string | null, readonly pickedUpAt?: string | null, readonly isOrderOnAccount: boolean, readonly transferableFrom: string, readonly transferableUntil: string, readonly restaurant: { readonly id: string; readonly type: RestaurantTypeEnum; readonly name: string; readonly pickupInstructions?: string | null }, readonly orderLines: ReadonlyArray<BaseOrderLineFragment>, readonly payment?: (
    BaseOrderPaymentFragment
    & PaymentOnOrderFragment
) | null }

export type BaseOrderPaymentFragment = { readonly method?: PaymentMethodEnum | null; readonly status: PaymentStatusEnum; readonly amount: number }

export type BaseOrderLineFragment = { readonly id: string; readonly totalPrice: number; readonly additions: ReadonlyArray<AdditionFragment>; readonly productVersion: BaseOrderLineProductVersionFragment; readonly currentProductVersion?: { readonly allergens: ReadonlyArray<AllergenFragment> } | null }

export type BaseOrderLineProductVersionFragment = { readonly id: string; readonly image?: { readonly variants: { readonly SQUARE_150: string } } | null; readonly product: { readonly id: string; readonly name: string; readonly type: ProductTypeEnum }; readonly allergens: ReadonlyArray<AllergenFragment> }

export type OrderHistoryOrderFragment = (
  { readonly updatedAt: string }
  & BaseOrderFragment
)

export type OrderDetailOrderFragment = (
  { readonly streetName?: string | null; readonly streetNumber?: string | null; readonly postalCode?: string | null; readonly cityName?: string | null; readonly isOrderOnAccount: boolean; readonly restaurant: RestaurantFragment }
  & BaseOrderFragment
)

export type PaymentOnOrderFragment = { readonly vouchers?: ReadonlyArray<VoucherOnOrderPaymentFragment> | null }

export type VoucherOnOrderPaymentFragment = { readonly voucherCode: string; readonly voucherOpenValue: number; readonly voucherUsedValue: number; readonly voucherValue: number }

export type PaymentQueryVariables = Exact<{
    id: Scalars['ID']['input']
}>


export type PaymentQuery = { readonly payment: PaymentDetailFragment }

export type CreatePaymentMutationVariables = Exact<{
    orderIds: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']
    vouchers?: InputMaybe<ReadonlyArray<VoucherUsageInput> | VoucherUsageInput>
}>


export type CreatePaymentMutation = { readonly createPayment: PaymentCreationResultFragment }

export type VerifyPaymentMutationVariables = Exact<{
    id: Scalars['ID']['input']
}>


export type VerifyPaymentMutation = { readonly verifyPayment: PaymentDetailFragment }

export type PaymentCreationResultFragment = { readonly paymentId: string; readonly pspRedirectUrl?: string | null }

export type PaymentDetailFragment = { readonly amount: number; readonly checkoutUrl?: string | null; readonly currency: CurrencyEnum; readonly feeAmount?: number | null; readonly id: string; readonly method?: PaymentMethodEnum | null; readonly molliePaymentId?: string | null; readonly refundedAmount: number; readonly status: PaymentStatusEnum; readonly vouchers?: ReadonlyArray<VoucherFragment> | null; readonly orders: ReadonlyArray<OrderDetailOrderFragment> }

export type QuestionsQueryVariables = Exact<{
    date: Scalars['Date']['input']
}>


export type QuestionsQuery = { readonly questions: ReadonlyArray<QuestionFragment> }

export type CreateAnswerMutationVariables = Exact<{
    input: CreateAnswerInput
}>


export type CreateAnswerMutation = { readonly createAnswer: AnswerFragment }

export type IsRatedByUserQueryVariables = Exact<{
    input: IsRatedByUserInput
}>


export type IsRatedByUserQuery = { readonly isRatedByUser: boolean }

export type CreateDishRatingMutationVariables = Exact<{
    input: CreateDishRatingInput
}>


export type CreateDishRatingMutation = { readonly createDishRating: { readonly id: string } }

export type QuestionFragment = { readonly id: string; readonly question: string; readonly type: QuestionTypeEnum; readonly placeholder?: string | null; readonly feedback?: string | null }

export type AnswerFragment = { readonly id: string; readonly answer: string; readonly question: { readonly id: string }; readonly user: { readonly id: string } }

export type RateableProductsQueryVariables = Exact<{ [key: string]: never }>


export type RateableProductsQuery = { readonly rateableProducts: ReadonlyArray<RateableProductFragment> }

export type CreateDismissRatingMutationVariables = Exact<{
    input: CreateDismissRatingInput
}>


export type CreateDismissRatingMutation = { readonly createDismissRating: { readonly id: string } }

export type RateableProductFragment = { readonly consumptionDate: string; readonly restaurant: { readonly id: string; readonly name: string }; readonly product: { readonly name: string; readonly id: string; readonly currentVersion: { readonly image?: { readonly variants: { readonly SQUARE_150: string } } | null } } }

export type RestaurantsQueryVariables = Exact<{
    input: RestaurantsInput
}>


export type RestaurantsQuery = { readonly restaurants: { readonly data: ReadonlyArray<RestaurantFragment>; readonly paginatorInfo: PaginatorFragment } }

export type RestaurantQueryVariables = Exact<{
    id: Scalars['ID']['input']
}>


export type RestaurantQuery = { readonly restaurant: RestaurantFragment }

export type RestaurantFragment = { readonly id: string; readonly name: string; readonly type: RestaurantTypeEnum; readonly hasTimeslots: boolean; readonly isQrCodePickup: boolean; readonly orderDeadlineMarginMinutes: number; readonly pickupInstructions?: string | null; readonly orderStrategies: ReadonlyArray<RestaurantOrderStrategyFragment>; readonly officeDays: ReadonlyArray<OfficeDayFragment>; readonly dishBackgroundImage?: { readonly ORIGINAL: string; readonly SQUARE_150: string; readonly SQUARE_300: string; readonly SQUARE_600: string } | null }

export type OfficeDayFragment = { readonly dayOfTheWeek: WeekDayEnum; readonly openingTime?: string | null; readonly closingTime?: string | null }

export type RestaurantOrderStrategyFragment = { readonly restaurantID: string; readonly clientID: string; readonly value: OrderStrategyEnum; readonly note?: string | null }

export type LocationsQueryVariables = Exact<{
    input: LocationsInput
}>


export type LocationsQuery = { readonly locations: { readonly data: ReadonlyArray<LocationFragment>; readonly paginatorInfo: PaginatorFragment } }

export type TimeslotsQueryVariables = Exact<{
    input: TimeslotsInput
}>


export type TimeslotsQuery = { readonly timeslots: ReadonlyArray<TimeslotFragment> }

export type TimeslotFragment = { readonly id: string; readonly capacityClaimed: string; readonly capacityTotal: string; readonly startsAt: string; readonly endsAt: string }

export type VouchersByEmailQueryVariables = Exact<{
    email: Scalars['String']['input']
}>


export type VouchersByEmailQuery = { readonly vouchers?: ReadonlyArray<VoucherFragment | null> | null }

export type VoucherFragment = { readonly id: number; readonly provider: IntegrationProviderEnum; readonly email: string; readonly endDate: string; readonly startDate: string; readonly voucherCode: string; readonly voucherOpenValue: number; readonly voucherUsedValue: number; readonly voucherValue: number }

export const LatestAppVersionsFragmentDoc = gql`
    fragment LatestAppVersions on LatestAppVersion {
  android
  ios
}
    `
export const TwoFactorSecretFragmentDoc = gql`
    fragment TwoFactorSecret on TwoFactorSecret {
  qr
  decrypted
}
    `
export const ClientSuggestionFragmentDoc = gql`
    fragment ClientSuggestion on Client {
  id
  registrationShortcode
  name
  emailQuestionsTo
}
    `
export const StartPkceLoginResponseFragmentDoc = gql`
    fragment startPkceLoginResponse on CreateLoginSessionResponse {
  redirectUri
  isFallbackRedirectUri
  clientResolutionStrategy
}
    `
export const AppCompatibilityStatusFragmentDoc = gql`
    fragment AppCompatibilityStatus on AppCompatibilityStatus {
  appVersion
  apiVersion
  compatible
  appSupportsApi
  apiSupportsApp
}
    `
export const RestaurantOrderStrategyFragmentDoc = gql`
    fragment RestaurantOrderStrategy on RestaurantOrderStrategy {
  restaurantID
  clientID
  value
  note
}
    `
export const OfficeDayFragmentDoc = gql`
    fragment OfficeDay on OfficeDay {
  dayOfTheWeek
  openingTime
  closingTime
}
    `
export const RestaurantFragmentDoc = gql`
    fragment Restaurant on Restaurant {
  id
  name
  type
  hasTimeslots
  isQrCodePickup
  orderDeadlineMarginMinutes
  orderStrategies {
    ...RestaurantOrderStrategy
  }
  pickupInstructions
  officeDays {
    ...OfficeDay
  }
  dishBackgroundImage {
    ORIGINAL
    SQUARE_150
    SQUARE_300
    SQUARE_600
  }
}
    ${RestaurantOrderStrategyFragmentDoc}
${OfficeDayFragmentDoc}`
export const DailyMenuItemSourceIDsFragmentDoc = gql`
    fragment DailyMenuItemSourceIDs on DailyMenuItemSourceIDs {
  menu
  menuEntry
  menuEntryOccurrence
  product
  productVersion
}
    `
export const DailyMenuItemContextFragmentDoc = gql`
    fragment DailyMenuItemContext on DailyMenuItemContext {
  date
  restaurantID
}
    `
export const TagFragmentDoc = gql`
    fragment Tag on Tag {
  id
  name
}
    `
export const PublicCategoryFragmentDoc = gql`
    fragment PublicCategory on PublicCategory {
  id
  name
  __typename
}
    `
export const AllergenFragmentDoc = gql`
    fragment Allergen on Allergen {
  id
  name
  icon
}
    `
export const NutrientFragmentDoc = gql`
    fragment Nutrient on Nutrient {
  NutrientType {
    unit
    name
    id
    isMacro
  }
  perHundredGram
}
    `
export const AdditionFragmentDoc = gql`
    fragment Addition on Addition {
  id
  name
  price
}
    `
export const DailyMenuItemImagesFragmentDoc = gql`
    fragment DailyMenuItemImages on DailyMenuItemImages {
  original
  square600
  square300
  square150
}
    `
export const ApiStockNumbersFragmentDoc = gql`
    fragment ApiStockNumbers on StockNumbers {
  amountTotal
  amountAvailable
  isConstrained
}
    `
export const DailyMenuItemFragmentDoc = gql`
    fragment DailyMenuItem on DailyMenuItem {
  sources {
    ...DailyMenuItemSourceIDs
  }
  context {
    ...DailyMenuItemContext
  }
  type
  name
  tagline
  description
  price
  dietaryClass
  tags {
    ...Tag
  }
  category: publicCategory {
    ...PublicCategory
  }
  allergens {
    ...Allergen
  }
  nutrients {
    ...Nutrient
  }
  additions {
    ...Addition
  }
  images {
    ...DailyMenuItemImages
  }
  stock {
    ...ApiStockNumbers
  }
}
    ${DailyMenuItemSourceIDsFragmentDoc}
${DailyMenuItemContextFragmentDoc}
${TagFragmentDoc}
${PublicCategoryFragmentDoc}
${AllergenFragmentDoc}
${NutrientFragmentDoc}
${AdditionFragmentDoc}
${DailyMenuItemImagesFragmentDoc}
${ApiStockNumbersFragmentDoc}`
export const DailyMenuRestaurantFragmentDoc = gql`
    fragment DailyMenuRestaurant on DailyMenuRestaurant {
  restaurant {
    ...Restaurant
  }
  items {
    ...DailyMenuItem
  }
}
    ${RestaurantFragmentDoc}
${DailyMenuItemFragmentDoc}`
export const DailyMenuFragmentDoc = gql`
    fragment DailyMenu on DailyMenu {
  date
  restaurants {
    ...DailyMenuRestaurant
  }
}
    ${DailyMenuRestaurantFragmentDoc}`
export const DietaryClassFragmentDoc = gql`
    fragment DietaryClass on DietaryClass {
  key
  value
}
    `
export const LoginResponseFragmentDoc = gql`
    fragment LoginResponse on AuthPayload {
  access_token
  expires_in
  refresh_token
  token_type
}
    `
export const RegisterResponseFragmentDoc = gql`
    fragment RegisterResponse on RegisterResponse {
  status
  tokens {
    ...LoginResponse
  }
}
    ${LoginResponseFragmentDoc}`
export const RefreshResponseFragmentDoc = gql`
    fragment RefreshResponse on RefreshTokenPayload {
  access_token
  expires_in
  refresh_token
  token_type
}
    `
export const PaginatorFragmentDoc = gql`
    fragment Paginator on PaginatorInfo {
  count
  currentPage
  firstItem
  hasMorePages
  lastItem
  lastPage
  perPage
  total
}
    `
export const AuthPayloadFragmentDoc = gql`
    fragment AuthPayload on AuthPayload {
  access_token
  expires_in
  refresh_token
  token_type
}
    `
export const LocationFragmentDoc = gql`
    fragment Location on Location {
  id
  name
  cityName
  postalCode
  streetName
  streetNumber
}
    `
export const ClientThemeFragmentDoc = gql`
    fragment ClientTheme on ClientTheme {
  colorPrimary
  colorPrimaryContrast
  colorSecondary
  colorSecondaryContrast
  colorTertiary
  colorTertiaryContrast
  colorTextDark
  colorTextLight
  colorSuccess
  colorSuccessContrast
  colorWarning
  colorWarningContrast
  colorError
  colorErrorContrast
  contextualColors {
    name
    value
  }
}
    `
export const ClientFragmentDoc = gql`
    fragment Client on Client {
  id
  registrationShortcode
  name
  emailQuestionsTo
  ratingContexts
  logo
  onboardingSteps
  themes {
    app {
      ...ClientTheme
    }
  }
}
    ${ClientThemeFragmentDoc}`
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  status
  firstName
  lastName
  email
  cityName
  postalCode
  streetName
  streetNumber
  newsReadAt
  requiresPrivacyPolicyAcceptance
  requiresTermsAcceptance
  hasConfirmedTwoFactor
  avatar
  client {
    emailQuestionsTo
  }
  officeDays {
    dayOfTheWeek
  }
  location {
    ...Location
  }
  client {
    ...Client
  }
  roles {
    name
    id
  }
  allergens {
    ...Allergen
  }
}
    ${LocationFragmentDoc}
${ClientFragmentDoc}
${AllergenFragmentDoc}`
export const NewsPageIndexFragmentDoc = gql`
    fragment NewsPageIndex on NewsPage {
  id
  intro
  published_at
  title
  heroImage
  tagline
}
    `
export const NewsPageFragmentDoc = gql`
    fragment NewsPage on NewsPage {
  ...NewsPageIndex
  articles {
    id
    title
    body
    image
    position
  }
}
    ${NewsPageIndexFragmentDoc}`
export const BaseOrderLineProductVersionFragmentDoc = gql`
    fragment BaseOrderLineProductVersion on ProductVersion {
  id
  image {
    variants {
      SQUARE_150
    }
  }
  product {
    id
    name
    type
  }
  allergens {
    ...Allergen
  }
}
    ${AllergenFragmentDoc}`
export const BaseOrderLineFragmentDoc = gql`
    fragment BaseOrderLine on OrderLine {
  id
  totalPrice
  additions {
    ...Addition
  }
  productVersion {
    ...BaseOrderLineProductVersion
  }
  currentProductVersion {
    allergens {
      ...Allergen
    }
  }
}
    ${AdditionFragmentDoc}
${BaseOrderLineProductVersionFragmentDoc}
${AllergenFragmentDoc}`
export const BaseOrderPaymentFragmentDoc = gql`
    fragment BaseOrderPayment on Payment {
  method
  status
  amount
}
    `
export const VoucherOnOrderPaymentFragmentDoc = gql`
    fragment VoucherOnOrderPayment on Voucher {
  voucherCode
  voucherOpenValue
  voucherUsedValue
  voucherValue
}
    `
export const PaymentOnOrderFragmentDoc = gql`
    fragment PaymentOnOrder on Payment {
  vouchers {
    ...VoucherOnOrderPayment
  }
}
    ${VoucherOnOrderPaymentFragmentDoc}`
export const BaseOrderFragmentDoc = gql`
    fragment BaseOrder on Order {
  id
  status
  totalPrice
  paidAt
  pickedUpAt
  isOrderOnAccount
  transferableFrom
  transferableUntil
  restaurant {
    id
    type
    name
    pickupInstructions
  }
  orderLines {
    ...BaseOrderLine
  }
  payment {
    ...BaseOrderPayment
    ...PaymentOnOrder
  }
}
    ${BaseOrderLineFragmentDoc}
${BaseOrderPaymentFragmentDoc}
${PaymentOnOrderFragmentDoc}`
export const OrderHistoryOrderFragmentDoc = gql`
    fragment OrderHistoryOrder on Order {
  ...BaseOrder
  updatedAt
}
    ${BaseOrderFragmentDoc}`
export const PaymentCreationResultFragmentDoc = gql`
    fragment PaymentCreationResult on Payment {
  paymentId: id
  pspRedirectUrl: checkoutUrl
}
    `
export const VoucherFragmentDoc = gql`
    fragment Voucher on Voucher {
  id
  provider
  email
  endDate
  startDate
  voucherCode
  voucherOpenValue
  voucherUsedValue
  voucherValue
}
    `
export const OrderDetailOrderFragmentDoc = gql`
    fragment OrderDetailOrder on Order {
  ...BaseOrder
  streetName
  streetNumber
  postalCode
  cityName
  isOrderOnAccount
  restaurant {
    ...Restaurant
  }
}
    ${BaseOrderFragmentDoc}
${RestaurantFragmentDoc}`
export const PaymentDetailFragmentDoc = gql`
    fragment PaymentDetail on Payment {
  amount
  checkoutUrl
  currency
  feeAmount
  id
  method
  molliePaymentId
  vouchers {
    ...Voucher
  }
  orders {
    ...OrderDetailOrder
  }
  refundedAmount
  status
}
    ${VoucherFragmentDoc}
${OrderDetailOrderFragmentDoc}`
export const QuestionFragmentDoc = gql`
    fragment Question on Question {
  id
  question
  type
  placeholder
  feedback
}
    `
export const AnswerFragmentDoc = gql`
    fragment Answer on Answer {
  id
  answer
  question {
    id
  }
  user {
    id
  }
}
    `
export const RateableProductFragmentDoc = gql`
    fragment RateableProduct on RateableProduct {
  consumptionDate
  restaurant {
    id
    name
  }
  product {
    name
    id
    currentVersion {
      image {
        variants {
          SQUARE_150
        }
      }
    }
  }
}
    `
export const TimeslotFragmentDoc = gql`
    fragment Timeslot on Timeslot {
  id
  capacityClaimed
  capacityTotal
  startsAt
  endsAt
}
    `
export const AllergensDocument = gql`
    query allergens {
  allergens {
    ...Allergen
  }
}
    ${AllergenFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class AllergensQueryService extends Apollo.Query<AllergensQuery, AllergensQueryVariables> {
    override document = AllergensDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const LatestAppVersionsDocument = gql`
    query latestAppVersions {
  latestAppVersions {
    ...LatestAppVersions
  }
}
    ${LatestAppVersionsFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class LatestAppVersionsQueryService extends Apollo.Query<LatestAppVersionsQuery, LatestAppVersionsQueryVariables> {
    override document = LatestAppVersionsDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const LogoutDocument = gql`
    mutation logout {
  logout {
    message
    status
  }
}
    `

@Injectable({
    providedIn: 'root',
})
export class LogoutMutationService extends Apollo.Mutation<LogoutMutation, LogoutMutationVariables> {
    override document = LogoutDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RefreshTokenDocument = gql`
    mutation refreshToken($input: RefreshTokenInput) {
  refreshToken(input: $input) {
    ...RefreshResponse
  }
}
    ${RefreshResponseFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class RefreshTokenMutationService extends Apollo.Mutation<RefreshTokenMutation, RefreshTokenMutationVariables> {
    override document = RefreshTokenDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `

@Injectable({
    providedIn: 'root',
})
export class ForgotPasswordMutationService extends Apollo.Mutation<ForgotPasswordMutation, ForgotPasswordMutationVariables> {
    override document = ForgotPasswordDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateForgottenPasswordDocument = gql`
    mutation updateForgottenPassword($input: NewPasswordWithCodeInput!) {
  updateForgottenPassword(input: $input) {
    message
    status
  }
}
    `

@Injectable({
    providedIn: 'root',
})
export class UpdateForgottenPasswordMutationService extends Apollo.Mutation<UpdateForgottenPasswordMutation, UpdateForgottenPasswordMutationVariables> {
    override document = UpdateForgottenPasswordDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const MeDocument = gql`
    query me {
  me {
    ...User
  }
}
    ${UserFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class MeQueryService extends Apollo.Query<MeQuery, MeQueryVariables> {
    override document = MeDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const WeekDayEnumsDocument = gql`
    query weekDayEnums {
  weekDayEnums
}
    `

@Injectable({
    providedIn: 'root',
})
export class WeekDayEnumsQueryService extends Apollo.Query<WeekDayEnumsQuery, WeekDayEnumsQueryVariables> {
    override document = WeekDayEnumsDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ClientSuggestionDocument = gql`
    query clientSuggestion($email: String!) {
  clientSuggestion(email: $email) {
    ...ClientSuggestion
  }
}
    ${ClientSuggestionFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class ClientSuggestionQueryService extends Apollo.Query<ClientSuggestionQuery, ClientSuggestionQueryVariables> {
    override document = ClientSuggestionDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateMeDocument = gql`
    mutation updateMe($input: UpdateMeInput!) {
  updateMe(input: $input) {
    id
  }
}
    `

@Injectable({
    providedIn: 'root',
})
export class UpdateMeMutationService extends Apollo.Mutation<UpdateMeMutation, UpdateMeMutationVariables> {
    override document = UpdateMeDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateUserAllergensDocument = gql`
    mutation updateUserAllergens($input: UpdateUserAllergensInput!) {
  updateUserAllergens(input: $input) {
    allergens {
      name
    }
  }
}
    `

@Injectable({
    providedIn: 'root',
})
export class UpdateUserAllergensMutationService extends Apollo.Mutation<UpdateUserAllergensMutation, UpdateUserAllergensMutationVariables> {
    override document = UpdateUserAllergensDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const DeleteMeDocument = gql`
    mutation deleteMe {
  deleteMe
}
    `

@Injectable({
    providedIn: 'root',
})
export class DeleteMeMutationService extends Apollo.Mutation<DeleteMeMutation, DeleteMeMutationVariables> {
    override document = DeleteMeDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateUserOfficeDaysDocument = gql`
    mutation updateUserOfficeDays($input: ChangeOfficeDaysInput!) {
  updateOfficeDays(input: $input) {
    id
    dayOfTheWeek
  }
}
    `

@Injectable({
    providedIn: 'root',
})
export class UpdateUserOfficeDaysMutationService extends Apollo.Mutation<UpdateUserOfficeDaysMutation, UpdateUserOfficeDaysMutationVariables> {
    override document = UpdateUserOfficeDaysDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RegisterDocument = gql`
    mutation register($input: RegisterInput!) {
  register(input: $input) {
    ...RegisterResponse
  }
}
    ${RegisterResponseFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class RegisterMutationService extends Apollo.Mutation<RegisterMutation, RegisterMutationVariables> {
    override document = RegisterDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ShortcodeRegisterDocument = gql`
    mutation shortcodeRegister($input: ShortcodeRegisterInput!) {
  shortcodeRegister(input: $input) {
    ...RegisterResponse
  }
}
    ${RegisterResponseFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class ShortcodeRegisterMutationService extends Apollo.Mutation<ShortcodeRegisterMutation, ShortcodeRegisterMutationVariables> {
    override document = ShortcodeRegisterDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const EnableTwoFactorDocument = gql`
    mutation enableTwoFactor {
  twoFactorAuthenticationEnable {
    ...TwoFactorSecret
  }
}
    ${TwoFactorSecretFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class EnableTwoFactorMutationService extends Apollo.Mutation<EnableTwoFactorMutation, EnableTwoFactorMutationVariables> {
    override document = EnableTwoFactorDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const DisableTwoFactorDocument = gql`
    mutation disableTwoFactor {
  twoFactorAuthenticationDisable
}
    `

@Injectable({
    providedIn: 'root',
})
export class DisableTwoFactorMutationService extends Apollo.Mutation<DisableTwoFactorMutation, DisableTwoFactorMutationVariables> {
    override document = DisableTwoFactorDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ConfirmTwoFactorCodeDocument = gql`
    mutation confirmTwoFactorCode($code: String!) {
  twoFactorAuthenticationConfirm(input: {code: $code})
}
    `

@Injectable({
    providedIn: 'root',
})
export class ConfirmTwoFactorCodeMutationService extends Apollo.Mutation<ConfirmTwoFactorCodeMutation, ConfirmTwoFactorCodeMutationVariables> {
    override document = ConfirmTwoFactorCodeDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const LocalLoginDocument = gql`
    mutation localLogin($clientId: ID!, $clientSecret: String!, $password: String!, $username: String!) {
  localLogin: login(
    input: {clientId: $clientId, clientSecret: $clientSecret, password: $password, username: $username}
  ) {
    ...LoginResponse
  }
}
    ${LoginResponseFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class LocalLoginMutationService extends Apollo.Mutation<LocalLoginMutation, LocalLoginMutationVariables> {
    override document = LocalLoginDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const StartPkceLoginDocument = gql`
    mutation startPkceLogin($emailAddress: String, $shortcode: String, $codeChallenge: String!, $fallbackRedirectUri: String!, $redirectUri: String!) {
  startPkceLogin: createLoginSession(
    input: {email: $emailAddress, redirectUri: $redirectUri, codeChallenge: $codeChallenge, fallbackRedirectUri: $fallbackRedirectUri, shortcode: $shortcode}
  ) {
    ...startPkceLoginResponse
  }
}
    ${StartPkceLoginResponseFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class StartPkceLoginMutationService extends Apollo.Mutation<StartPkceLoginMutation, StartPkceLoginMutationVariables> {
    override document = StartPkceLoginDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const FinishPkceLoginDocument = gql`
    mutation finishPkceLogin($codeVerifier: String!, $exchangeToken: String!, $policiesAccepted: Boolean!) {
  finishPkceLogin: finishLoginSession(
    input: {acceptsPrivacyPolicy: $policiesAccepted, acceptsTerms: $policiesAccepted, codeVerifier: $codeVerifier, exchangeToken: $exchangeToken}
  ) {
    ...AuthPayload
  }
}
    ${AuthPayloadFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class FinishPkceLoginMutationService extends Apollo.Mutation<FinishPkceLoginMutation, FinishPkceLoginMutationVariables> {
    override document = FinishPkceLoginDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CompatibilityDocument = gql`
    query compatibility($appVersion: Semver!, $supportedApiRange: SemverRange!) {
  appCompatibility(appVersion: $appVersion, supportedApiRange: $supportedApiRange) {
    ...AppCompatibilityStatus
  }
}
    ${AppCompatibilityStatusFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class CompatibilityQueryService extends Apollo.Query<CompatibilityQuery, CompatibilityQueryVariables> {
    override document = CompatibilityDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const DailyMenuDocument = gql`
    query dailyMenu($clientID: ID!, $date: Date!, $filters: DailyMenuFilters) {
  dailyMenu(clientID: $clientID, date: $date, filters: $filters) {
    ...DailyMenu
  }
}
    ${DailyMenuFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class DailyMenuQueryService extends Apollo.Query<DailyMenuQuery, DailyMenuQueryVariables> {
    override document = DailyMenuDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const DailyMenuItemDocument = gql`
    query dailyMenuItem($menuEntryOccurrenceID: ID!) {
  dailyMenuItem(menuEntryOccurrenceID: $menuEntryOccurrenceID) {
    ...DailyMenuItem
  }
}
    ${DailyMenuItemFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class DailyMenuItemQueryService extends Apollo.Query<DailyMenuItemQuery, DailyMenuItemQueryVariables> {
    override document = DailyMenuItemDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const FeedFiltersDocument = gql`
    query feedFilters($locationID: ID!, $clientID: ID!) {
  restaurants(input: {first: 1000, page: 1, filters: {locations: [$locationID]}}) {
    data {
      ...Restaurant
    }
  }
  publicCategories(
    input: {page: 1, first: 1000, filters: {clients: [$clientID], locations: [$locationID]}}
  ) {
    data {
      ...PublicCategory
    }
  }
  dietaryClasses {
    ...DietaryClass
  }
}
    ${RestaurantFragmentDoc}
${PublicCategoryFragmentDoc}
${DietaryClassFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class FeedFiltersQueryService extends Apollo.Query<FeedFiltersQuery, FeedFiltersQueryVariables> {
    override document = FeedFiltersDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const NewsPagesDocument = gql`
    query newsPages($input: NewsPagesInput!) {
  newsPages(input: $input) {
    data {
      ...NewsPageIndex
    }
  }
}
    ${NewsPageIndexFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class NewsPagesQueryService extends Apollo.Query<NewsPagesQuery, NewsPagesQueryVariables> {
    override document = NewsPagesDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const NewsPageDocument = gql`
    query newsPage($id: ID!) {
  newsPage(id: $id) {
    ...NewsPage
  }
}
    ${NewsPageFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class NewsPageQueryService extends Apollo.Query<NewsPageQuery, NewsPageQueryVariables> {
    override document = NewsPageDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const OrderHistoryDocument = gql`
    query orderHistory($input: OrderHistoryInput!) {
  orderHistory(input: $input) {
    paginatorInfo {
      ...Paginator
    }
    data {
      ...OrderHistoryOrder
    }
  }
}
    ${PaginatorFragmentDoc}
${OrderHistoryOrderFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class OrderHistoryQueryService extends Apollo.Query<OrderHistoryQuery, OrderHistoryQueryVariables> {
    override document = OrderHistoryDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const LatestOrderDocument = gql`
    query latestOrder($startDate: Date) {
  orderHistory(
    input: {sorting: PAID_AT_DESC, pagination: {first: 1, page: 1}, filter: {consumptionDateRange: {startDate: $startDate}}}
  ) {
    data {
      ...BaseOrder
    }
  }
}
    ${BaseOrderFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class LatestOrderQueryService extends Apollo.Query<LatestOrderQuery, LatestOrderQueryVariables> {
    override document = LatestOrderDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const PickupOrdersDocument = gql`
    query pickupOrders($restaurant: ID!, $startDate: Date!, $endDate: Date!, $page: Int!) {
  orderHistory(
    input: {sorting: PAID_AT_DESC, pagination: {first: 100, page: $page}, filter: {consumptionDateRange: {startDate: $startDate, endDate: $endDate}, restaurant: {connect: $restaurant}, excludePickedUp: true}}
  ) {
    data {
      ...BaseOrder
    }
    paginatorInfo {
      ...Paginator
    }
  }
}
    ${BaseOrderFragmentDoc}
${PaginatorFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class PickupOrdersQueryService extends Apollo.Query<PickupOrdersQuery, PickupOrdersQueryVariables> {
    override document = PickupOrdersDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RequestOrderReceiptDocument = gql`
    query requestOrderReceipt($id: ID!) {
  requestOrderReceipt(id: $id) {
    status
  }
}
    `

@Injectable({
    providedIn: 'root',
})
export class RequestOrderReceiptQueryService extends Apollo.Query<RequestOrderReceiptQuery, RequestOrderReceiptQueryVariables> {
    override document = RequestOrderReceiptDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const OrderDetailDocument = gql`
    query orderDetail($id: ID!) {
  order(id: $id) {
    ...OrderDetailOrder
  }
}
    ${OrderDetailOrderFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class OrderDetailQueryService extends Apollo.Query<OrderDetailQuery, OrderDetailQueryVariables> {
    override document = OrderDetailDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CreateOrdersDocument = gql`
    mutation CreateOrders($orders: [OrderCreationInput!]!) {
  createOrders(orders: $orders) {
    ...OrderDetailOrder
  }
}
    ${OrderDetailOrderFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class CreateOrdersMutationService extends Apollo.Mutation<CreateOrdersMutation, CreateOrdersMutationVariables> {
    override document = CreateOrdersDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const PickupOrderDocument = gql`
    mutation pickupOrder($id: ID!, $selectedOrderLineIds: [ID!]!) {
  pickUpOrder(input: {orderId: $id, orderLineIds: $selectedOrderLineIds}) {
    ...BaseOrder
  }
}
    ${BaseOrderFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class PickupOrderMutationService extends Apollo.Mutation<PickupOrderMutation, PickupOrderMutationVariables> {
    override document = PickupOrderDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CancelOrderDocument = gql`
    mutation cancelOrder($id: ID!) {
  setOrderStatus(orderID: $id, status: CANCELED) {
    nextStatus
    orderID
  }
}
    `

@Injectable({
    providedIn: 'root',
})
export class CancelOrderMutationService extends Apollo.Mutation<CancelOrderMutation, CancelOrderMutationVariables> {
    override document = CancelOrderDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const PaymentDocument = gql`
    query payment($id: ID!) {
  payment(id: $id) {
    ...PaymentDetail
  }
}
    ${PaymentDetailFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class PaymentQueryService extends Apollo.Query<PaymentQuery, PaymentQueryVariables> {
    override document = PaymentDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CreatePaymentDocument = gql`
    mutation createPayment($orderIds: [ID!]!, $vouchers: [VoucherUsageInput!]) {
  createPayment(orderIds: $orderIds, vouchers: $vouchers) {
    ...PaymentCreationResult
  }
}
    ${PaymentCreationResultFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class CreatePaymentMutationService extends Apollo.Mutation<CreatePaymentMutation, CreatePaymentMutationVariables> {
    override document = CreatePaymentDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const VerifyPaymentDocument = gql`
    mutation verifyPayment($id: ID!) {
  verifyPayment(id: $id) {
    ...PaymentDetail
  }
}
    ${PaymentDetailFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class VerifyPaymentMutationService extends Apollo.Mutation<VerifyPaymentMutation, VerifyPaymentMutationVariables> {
    override document = VerifyPaymentDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const QuestionsDocument = gql`
    query questions($date: Date!) {
  questions(input: {date: $date}) {
    ...Question
  }
}
    ${QuestionFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class QuestionsQueryService extends Apollo.Query<QuestionsQuery, QuestionsQueryVariables> {
    override document = QuestionsDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CreateAnswerDocument = gql`
    mutation createAnswer($input: CreateAnswerInput!) {
  createAnswer(input: $input) {
    ...Answer
  }
}
    ${AnswerFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class CreateAnswerMutationService extends Apollo.Mutation<CreateAnswerMutation, CreateAnswerMutationVariables> {
    override document = CreateAnswerDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const IsRatedByUserDocument = gql`
    query isRatedByUser($input: IsRatedByUserInput!) {
  isRatedByUser(input: $input)
}
    `

@Injectable({
    providedIn: 'root',
})
export class IsRatedByUserQueryService extends Apollo.Query<IsRatedByUserQuery, IsRatedByUserQueryVariables> {
    override document = IsRatedByUserDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CreateDishRatingDocument = gql`
    mutation createDishRating($input: CreateDishRatingInput!) {
  createDishRating(input: $input) {
    id
  }
}
    `

@Injectable({
    providedIn: 'root',
})
export class CreateDishRatingMutationService extends Apollo.Mutation<CreateDishRatingMutation, CreateDishRatingMutationVariables> {
    override document = CreateDishRatingDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RateableProductsDocument = gql`
    query rateableProducts {
  rateableProducts {
    ...RateableProduct
  }
}
    ${RateableProductFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class RateableProductsQueryService extends Apollo.Query<RateableProductsQuery, RateableProductsQueryVariables> {
    override document = RateableProductsDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CreateDismissRatingDocument = gql`
    mutation createDismissRating($input: CreateDismissRatingInput!) {
  createDismissRating(input: $input) {
    id
  }
}
    `

@Injectable({
    providedIn: 'root',
})
export class CreateDismissRatingMutationService extends Apollo.Mutation<CreateDismissRatingMutation, CreateDismissRatingMutationVariables> {
    override document = CreateDismissRatingDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RestaurantsDocument = gql`
    query restaurants($input: RestaurantsInput!) {
  restaurants(input: $input) {
    data {
      ...Restaurant
    }
    paginatorInfo {
      ...Paginator
    }
  }
}
    ${RestaurantFragmentDoc}
${PaginatorFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class RestaurantsQueryService extends Apollo.Query<RestaurantsQuery, RestaurantsQueryVariables> {
    override document = RestaurantsDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RestaurantDocument = gql`
    query restaurant($id: ID!) {
  restaurant(id: $id) {
    ...Restaurant
  }
}
    ${RestaurantFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class RestaurantQueryService extends Apollo.Query<RestaurantQuery, RestaurantQueryVariables> {
    override document = RestaurantDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const LocationsDocument = gql`
    query locations($input: LocationsInput!) {
  locations(input: $input) {
    data {
      ...Location
    }
    paginatorInfo {
      ...Paginator
    }
  }
}
    ${LocationFragmentDoc}
${PaginatorFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class LocationsQueryService extends Apollo.Query<LocationsQuery, LocationsQueryVariables> {
    override document = LocationsDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const TimeslotsDocument = gql`
    query timeslots($input: timeslotsInput!) {
  timeslots(input: $input) {
    ...Timeslot
  }
}
    ${TimeslotFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class TimeslotsQueryService extends Apollo.Query<TimeslotsQuery, TimeslotsQueryVariables> {
    override document = TimeslotsDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const VouchersByEmailDocument = gql`
    query vouchersByEmail($email: String!) {
  vouchers(email: $email) {
    ...Voucher
  }
}
    ${VoucherFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class VouchersByEmailQueryService extends Apollo.Query<VouchersByEmailQuery, VouchersByEmailQueryVariables> {
    override document = VouchersByEmailDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}